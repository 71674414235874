import userService from "@/api/services/user-service.ts";
import { queryOptions } from "@tanstack/react-query";

export const userQueryOptions = (username: string) => {
	return queryOptions({
		queryKey: ["user", username],
		queryFn: () => userService.getByUsername(username),
	});
};

export const userFollowersQueryOptions = (username: string) => {
	return queryOptions({
		queryKey: ["user_followers", username],
		queryFn: () => userService.getFollowersByUsername(username),
	});
};

export const userFollowingQueryOptions = (username: string) => {
	return queryOptions({
		queryKey: ["user_following", username],
		queryFn: () => userService.getFollowingByUsername(username),
	});
};
