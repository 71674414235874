import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/components/core/actions/dropdown-menu.tsx";
import {
	IconChevronLeft,
	IconChevronRight,
	IconDotsHor,
	IconFullscreen,
	IconScroll,
	IconShare,
	IconSwipe,
	IconTap,
} from "@/components/mvicons";
import ReaderDropdownItems from "@/components/reader/reader-dropdown-items.tsx";
import { IconX } from "@tabler/icons-react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import ReactDOM from "react-dom";
import ScrollReader from "./scroll-reader";
import SwipeReader from "./swipe-reader";
import TapReader from "./tap-reader";

const Reader = ({ isOpen, onClose }) => {
	const [isActive, setIsActive] = useState(false);
	const [activeMode, setActiveMode] = useState("tap"); // Default mode is 'tap'

	const handleButtonClick = (mode) => {
		setActiveMode(mode);
	};

	const renderContent = () => {
		switch (activeMode) {
			case "scroll":
				return (
					<div className="flex flex-col justify-center items-center h-full w-auto">
						<ScrollReader />
					</div>
				);
			case "swipe":
				return (
					<div className="flex flex-col justify-center items-center h-full w-auto">
						<SwipeReader />
					</div>
				);
			case "tap":
				return (
					<div className="flex flex-col justify-center items-center h-full w-auto">
						<TapReader />
					</div>
				);
		}
	};

	return ReactDOM.createPortal(
		<AnimatePresence>
			{isOpen && (
				<motion.div
					className="font-aller fixed inset-0 flex items-center justify-center z-50"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.1 }}
				>
					{/* reader ui overlay */}
					<motion.div
						className="absolute inset-0 bg-mvdark-950/60"
						initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
						animate={{ opacity: 1, backdropFilter: "blur(3px)" }}
						exit={{ opacity: 0, backdropFilter: "blur(0px)" }}
						transition={{ duration: 0.1 }}
					/>

					{/* reader ui container */}
					<motion.div
						className="relative flex flex-col h-auto w-auto"
						initial={{ opacity: 0, scale: 0.95 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.95 }}
						transition={{ duration: 0.1 }}
					>
						{/* close reader */}
						<button
							onClick={() => {
								onClose();
								document.body.style.overflow = "";
							}}
							type="button"
							className="z-10 absolute left-8 top-8 inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white"
						>
							<IconX size={28} stroke={2} />
						</button>

						{/* dropdown */}
						<div className="z-10 absolute right-8 top-8">
							<DropdownMenu
								modal={false}
								onOpenChange={(open) => setIsActive(open)}
							>
								<DropdownMenuTrigger asChild>
									<button
										type="button"
										className={clsx(
											"inline-flex flex-col justify-center items-center w-[38px] h-[38px] rounded-xl ",
											{
												"bg-white text-mvdark-950": isActive,
												"bg-white/20 hover:bg-white/30 text-white": !isActive,
											},
										)}
									>
										<IconDotsHor size={18} />
									</button>
								</DropdownMenuTrigger>
								<DropdownMenuContent sideOffset={-12} alignOffset={0}>
									<ReaderDropdownItems />
								</DropdownMenuContent>
							</DropdownMenu>
						</div>

						{/* reader utility */}
						<div className="z-10 absolute right-8 bottom-8 inline-flex flex-row items-center w-auto gap-6">
							<button
								type="button"
								className="inline-flex flex-col items-center justify-center gap-2 text-white opacity-50 hover:opacity-100"
							>
								<IconShare size={24} />
							</button>
							<button
								type="button"
								className="inline-flex flex-col items-center justify-center gap-2 text-white opacity-50 hover:opacity-100"
							>
								<IconFullscreen size={22} />
							</button>
						</div>

						{/* reader ui contents */}
						<div className="relative flex flex-col items-center gap-8">
							{/* Reader options */}
							<div className="z-10 absolute left-1/2 -translate-x-1/2 bottom-12 inline-flex flex-row items-center w-auto py-6 px-8 rounded-md gap-8 bg-black/20 backdrop-blur">
								<button
									type="button"
									className={`inline-flex flex-col items-center justify-center gap-2 text-white ${activeMode === "tap" ? "opacity-100" : "opacity-50"} hover:opacity-100`}
									onClick={() => handleButtonClick("tap")}
								>
									<IconTap size={32} />
									<span className="text-xs uppercase tracking-widest">Tap</span>
								</button>
								<button
									type="button"
									className={`inline-flex flex-col items-center justify-center gap-2 text-white ${activeMode === "scroll" ? "opacity-100" : "opacity-50"} hover:opacity-100`}
									onClick={() => handleButtonClick("scroll")}
								>
									<IconScroll size={32} />
									<span className="text-xs uppercase tracking-widest">
										Scroll
									</span>
								</button>
								<button
									type="button"
									className={`inline-flex flex-col items-center justify-center gap-2 text-white ${activeMode === "swipe" ? "opacity-100" : "opacity-50"} hover:opacity-100`}
									onClick={() => handleButtonClick("swipe")}
								>
									<IconSwipe size={32} />
									<span className="text-xs uppercase tracking-widest">
										Swipe
									</span>
								</button>
							</div>

							{/* Main reader */}
							<motion.div className="relative flex flex-col justify-center items-center h-screen w-screen bg-black overflow-hidden">
								{renderContent()}
							</motion.div>
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>,
		document.body,
	);
};

export default Reader;
