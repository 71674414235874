import activityService from "@/api/services/activity-service.ts";
import { queryOptions } from "@tanstack/react-query";
import { z } from "zod";

export const activitySearchQuery = z.object({
	page: z.string().optional(),
});

export const activityQueryOptions = (
	search?: z.infer<typeof activitySearchQuery>,
) => {
	return queryOptions({
		queryKey: ["activities"],
		queryFn: () => activityService.list(search),
	});
};
