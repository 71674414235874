import { IconChevronLeft, IconChevronRight } from "@/components/mvicons";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { IconArrowNarrowUp } from "@tabler/icons-react";
import { FreeMode, Mousewheel, Pagination } from "swiper/modules";

const TapReader = () => {
	const [animateDetails, setAnimateDetails] = useState(false); // Control animation state
	const [showDetails, setShowDetails] = useState(true);
	const [isVisible, setIsVisible] = useState(true);

	const handleSwipeUp = () => {
		setAnimateDetails(true); // Animate top section details
		setTimeout(() => {
			setIsVisible(false); // Hide element permanently
			setShowDetails(false); // hide details permanently
		}, 500); // Match this duration with the animation duration
	};

	const SwiperUpTrigger = () => {
		return (
			<AnimatePresence>
				{isVisible && (
					<motion.div
						className="z-[4] fixed top-1/2 left-1/2 w-[160px] h-[160px] -ml-[80px] -mt-[80px] flex flex-col items-center justify-center shadow-lg cursor-pointer"
						drag="y"
						dragConstraints={{ top: -60, bottom: 0 }}
						dragElastic={0.2}
						onDragEnd={(e, info) => {
							if (info.offset.y < -30) {
								handleSwipeUp();
							}
						}}
						initial={{ opacity: 1 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0, transition: { duration: 0.3 } }}
						style={{ display: animateDetails ? "none" : "flex" }}
					>
						<IconArrowNarrowUp className="text-white" size={100} stroke={1} />
						<span className="text-left text-white">Swipe up to start</span>
					</motion.div>
				)}
			</AnimatePresence>
		);
	};

	return (
		<div className="relative flex flex-col h-full w-full">
			{/* tap reader preview */}
			{showDetails && <SwiperUpTrigger />}

			{/* reader ui contents */}
			{showDetails && (
				<motion.div
					className="z-[3] fixed top-16 left-1/2 inline-flex flex-col w-auto gap-6 "
					initial={{ y: 0, x: "-50%", opacity: 1 }}
					animate={
						animateDetails ? { y: -100, opacity: 0 } : { y: 0, opacity: 1 }
					}
					transition={{
						duration: 0.5,
						ease: [0.33, 1, 0.68, 1],
					}}
				>
					<div className="flex flex-col justify-center items-center w-full gap-3">
						<div className="flex justify-center items-center w-full p-4 rounded-md bg-white/30 text-white">
							Logo
						</div>
						<div className="inline-flex flex-row items-center divide-x-2">
							<span className="px-2 uppercase text-sm text-white/70 leading-tight">
								Season 1
							</span>
							<span className="px-2 uppercase text-sm text-white/70 leading-tight">
								Episode 1
							</span>
						</div>
					</div>
					<div className="flex flex-row items-center w-full">
						<span className="font-bold text-3xl text-white">11+</span>
						<div className="inline-flex flex-col w-auto py-1 pl-4 ml-4 border-l-2 border-white">
							<span className="text-white/70 leading-tight">
								Cartoon Violence
							</span>
							<span className="text-white/70 leading-tight">Mature Themes</span>
							<span className="text-white/70 leading-tight">
								Some Rating Item
							</span>
							<span className="text-white/70 leading-tight">
								Another Rating item
							</span>
						</div>
					</div>
				</motion.div>
			)}

			{showDetails && (
				<motion.div
					className="z-[2] fixed isnet-0 w-full h-full bg-black"
					initial={{ opacity: 1 }}
					animate={animateDetails ? { opacity: 0 } : { opacity: 1 }}
					transition={{
						duration: 0.5,
						ease: [0.33, 1, 0.68, 1],
					}}
				/>
			)}

			{/* actual page viewer */}
			<div className="z-[1] flex w-full h-full">
				<Swiper
					slidesPerView="auto"
					allowTouchMove={true}
					direction="vertical" // Enable vertical direction
					mousewheel={true} // Enable mouse wheel scrolling
					freeMode={true} // Enable free-flowing scrolling
					spaceBetween={0}
					pagination={{
						type: "progressbar", // Define type
						clickable: false, // Make it clickable
					}}
					modules={[Pagination, Mousewheel, FreeMode]} // Include necessary modules
					className="scroll-reader w-screen h-screen"
				>
					<div className="swiper-pagination-progressbar !z-50 !top-auto !bottom-0 !left-0 !right-0 !h-[4px]" />

					<SwiperSlide className="flex justify-center items-center w-full h-full bg-red-600">
						<span className="text-white text-2xl">Goblin leader strikes!</span>
					</SwiperSlide>
					<SwiperSlide className="flex justify-center items-center w-full h-full bg-green-600">
						<span className="text-white text-2xl">Ka pow!</span>
					</SwiperSlide>
					<SwiperSlide className="flex justify-center items-center w-full h-full bg-blue-600">
						<span className="text-white text-2xl">Knight is indured!</span>
					</SwiperSlide>
					<SwiperSlide className="flex justify-center items-center w-full h-full bg-orange-600">
						<span className="text-white text-2xl">Losing health...</span>
					</SwiperSlide>
					<SwiperSlide className="flex justify-center items-center w-full h-full bg-gray-600">
						<span className="text-white text-2xl">...End</span>
					</SwiperSlide>
				</Swiper>
			</div>
		</div>
	);
};

export default TapReader;
