import { collectionsQueryOptions } from "@/api/queries/collection-queries.ts";
import { profileQueryOptions } from "@/api/queries/profile-queries.ts";
import CollectionBadges from "@/components/collections/collection-badges";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

export const Route = createFileRoute("/_profile/badges")({
	component: Page,
	loader: ({ context: { queryClient } }) => {
		return queryClient.ensureQueryData(collectionsQueryOptions());
	},
});

function Page() {
	const [showAllItems, setShowAllItems] = useState(false);
	const [showAllBadges, setShowAllBadges] = useState(false);
	const collectionQuery = useSuspenseQuery(collectionsQueryOptions());
	const collections = collectionQuery.data;
	const profileQuery = useQuery(profileQueryOptions());
	const profile = profileQuery.data;

	const handleShowAllBadges = () => {
		setShowAllBadges((prev) => !prev);
	};

	return (
		<div className="flex flex-col w-full gap-6">
			<div className="flex flex-col w-full gap-4">
				<div className="flex flex-row items-start sm:items-end justify-between gap-2 sm:gap-4">
					<div className="inline-flex flex-col w-auto">
						<span className="text-sm font-semibold text-white">
							Your Badges
						</span>
					</div>
					<div className="inline-flex flex-row items-center w-auto gap-1">
						<span className="text-sm text-white/50">+16 |</span>
						<button
							onClick={handleShowAllBadges}
							type="button"
							className="text-sm text-white/50 hover:text-white"
						>
							{showAllBadges ? "Show Less" : "Show All"}
						</button>
					</div>
				</div>
				<div className="flex flex-col w-full gap-4">
					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-1.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 1
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>

					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-2.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 2
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>

					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-3.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 3
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>

					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-4.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 4
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>

					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-5.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 5
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>

					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-6.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 6
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>

					<div className="flex flex-row items-center w-full rounded-xl gap-4 md:gap-6 p-4 md:p-6 lg:p-8 bg-white bg-opacity-5">
						<div className="inline-flex shrink-0 self-start w-[100px] h-auto text-white">
							<img
								src="/badge-7.png"
								alt="Macroverse"
								className="w-full object-contain rounded-xl"
							/>
						</div>
						<div className="flex flex-col w-full max-w-[560px] gap-3">
							<span className="text-md font-semibold text-white leading-tight">
								Badge 7
							</span>
							<span className="text-sm text-white/70 leading-tight line-clamp-4">
								Unlock and collect special badges to show off your fandom,
								activity, and impeccable taste!
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
