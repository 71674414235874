import { apiClient } from "@/api/api-client.ts";
import type { Reward } from "@/types/reward.ts";

const rewardService = {
	getRewardStatus: async () => {
		const response = await apiClient.get<Reward>("/rewards");

		return response.data;
	},
	claimReward: async () => {
		const response = await apiClient.post<Reward>("/claim_rewards");

		return response.data;
	},
};

export default rewardService;
