import { differenceInSeconds, parseISO } from "date-fns";

/**
 * Calculates the difference in seconds between the given date string and now.
 * @param dateString - The date string to compare against the current time (ISO 8601 format recommended).
 * @returns The duration in seconds as a number.
 */
export function timeDiffInSeconds(dateString: string): number {
	const parsedDate = parseISO(dateString);
	const now = new Date();
	return differenceInSeconds(parsedDate, now);
}
