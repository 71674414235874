import { apiClient } from "@/api/api-client.ts";
import type { User } from "@/types/user.ts";

const userService = {
	getByUsername: async (username: string) => {
		const response = await apiClient.get<User>(`/user/${username}`);

		return response.data;
	},
	getFollowersByUsername: async (username: string) => {
		const response = await apiClient.get<User[]>(`/user/${username}/followers`);

		return response.data;
	},
	getFollowingByUsername: async (username: string) => {
		const response = await apiClient.get<User[]>(`/user/${username}/following`);

		return response.data;
	},
};

export default userService;
