import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { useModalControl } from "@/components/core/modals/use-modal-controller.ts";
import { useModal } from "@/components/core/modals/use-modal.ts";
import { IconExp, IconInfo, IconMacronaut } from "@/components/mvicons";

export default function RewardModal() {
	const { close } = useModalControl();

	return (
		<div className="flex flex-col justify-center items-center w-full">
			<div className="group relative flex flex-col w-auto cursor-pointer">
				<div className="z-[3] rewards-card rewards-card--main inline-flex flex-col w-[200px] rounded-[28px] overflow-hidden">
					<div className="flex flex-col w-full h-auto rounded-t-3xl p-[5px] pb-[4px] bg-mvdark-950/80 overflow-hidden">
						<div className="relative flex flex-col w-full h-full rounded-t-3xl bg-purple-600 overflow-hidden">
							<div className="z-[2] absolute inset-0 flex flex-col w-full rounded-t-3xl p-2 gap-4 bg-gradient-to-b from-transparent to-black/70">
								<div className="flex flex-row items-center justify-between w-full gap-4">
									<div className="inline-flex">
										<IconMacronaut className="text-white" size={28} />
									</div>
									<div className="inline-flex">
										<IconInfo className="text-white" size={24} />
									</div>
								</div>
								<span className="mt-auto px-4 font-bold text-lg text-center text-white leading-6">
									Claim your first XP
								</span>
							</div>
							<img
								className="z-[1] block w-full"
								src="/rewards-card-image-1.jpg"
								alt="Macroverse"
							/>
						</div>
					</div>
					<div className="flex flex-row justify-center w-full py-2 px-3 gap-4 bg-mvdark-950/60">
						<div className="inline-flex items-center flex-col w-auto gap-1">
							<IconExp className="text-white" size={20} />
							<span className="text-3xs text-white">100</span>
						</div>
					</div>
					<div className="flex flex-row w-full p-3 bg-mvdark-950/40">
						<Button
							onClick={() => {
								close();
							}}
							type="button"
							className="font-quagmire w-full bg-mvdark-950/60 uppercase"
							size="md"
							variant="gradient"
							gradient="default"
						>
							<ButtonLabel>Claim</ButtonLabel>
						</Button>
					</div>
				</div>
				<div className="z-[2] absolute inset-0 rounded-[28px] opacity-50 bg-mvmain-gradient" />
				<div className="z-[1] absolute inset-0 rounded-[28px] blur-lg scale-y-105 scale-x-105 transition-all animate-pulse bg-mvmain-gradient" />
			</div>
		</div>
	);
}
