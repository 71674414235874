import { IconAchievements } from "@/components/mvicons";
import { useWindowSize } from "usehooks-ts";

export default function CollectionBadges() {
	const { width = 0 } = useWindowSize();
	const isMobile = width < 640;
	const isTablet = width >= 640 && width < 768;

	const badges = [
		{ image: "/badge-1.png", altText: "Badge 1" },
		{ image: "/badge-2.png", altText: "Badge 2" },
		{ image: "/badge-3.png", altText: "Badge 3" },
		/*{ image: "/badge-4.png", altText: "Badge 4" },
		{ image: "/badge-5.png", altText: "Badge 5" },
		{ image: "/badge-6.png", altText: "Badge 6" },
		{ image: "/badge-7.png", altText: "Badge 7" },*/
	];

	const displayBadges = (() => {
		if (isMobile) {
			return badges.slice(0, 4);
		}
		if (isTablet) {
			return badges.slice(0, 5);
		}
		return badges.slice(0, 7);
	})();

	return (
		<div className="flex flex-row justify-center lg:justify-start w-full rounded-xl gap-6 p-8 bg-white bg-opacity-5">
			{displayBadges.map((badge, index) => (
				<div
					key={index}
					className="flex justify-center items-center w-auto rounded-xl text-white"
				>
					<img
						src={badge.image}
						alt={badge.altText}
						className="h-full object-contain rounded-xl"
					/>
				</div>
			))}

			{badges.length < 6 && (
				<div className="hidden md:flex flex-row justify-center w-full max-w-[320px] gap-3 ml-3">
					{badges.length < 4 && (
						<div className="inline-flex text-white">
							<IconAchievements size={38} />
						</div>
					)}
					<div className="flex flex-col w-full gap-3">
						<span className="text-sm font-semibold text-white leading-tight">
							Achievements Rewarded!
						</span>
						<span className="text-sm text-white/70 leading-tight line-clamp-4">
							Unlock and collect special badges to show off your fandom,
							activity, and impeccable taste!
						</span>
					</div>
				</div>
			)}
		</div>
	);
}
