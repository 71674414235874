import { z } from "zod";

export const userMetadataSchema = z.object({
	website: z.union([
		z
			.string()
			.refine(
				(value) =>
					!value ||
					/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[\w.-]*)*\/?$/.test(
						value,
					),
				{
					message: "Please provide a valid URL",
				},
			),
		// .url({ message: "Invalid website URL" }),
		z.literal(""),
	]),
	customLink: z
		.union([
			z
				.string()
				.refine(
					(value) =>
						!value ||
						/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[\w.-]*)*\/?$/.test(
							value,
						),
					{
						message: "Please provide a valid URL",
					},
				),
			// .url({ message: "Invalid custom link URL" }),
			z.literal(""),
		])
		.optional(),
	showWelcome: z.boolean().optional(),
	profileBgColor: z.string().optional(),
	twitterHandler: z
		.union([
			z
				.string()
				.regex(/^[a-zA-Z0-9](?!.*[-_.]{2})[a-zA-Z0-9._-]*[a-zA-Z0-9]$/, {
					message: "Invalid twitter handler",
				}),
			z.literal(""),
		])
		.optional(),
	instagramHandler: z
		.union([
			z
				.string()
				.regex(/^[a-zA-Z0-9](?!.*[-_.]{2})[a-zA-Z0-9._-]*[a-zA-Z0-9]$/, {
					message: "Invalid instagram handler",
				}),
			z.literal(""),
		])
		.optional(),
});

export const userSchema = z.object({
	id: z.string().optional().nullable(),
	email: z.string().optional().nullable(),
	macronautId: z.number().optional().nullable(),
	username: z
		.string()
		.min(3, {
			message: "Username must be at least 3 characters long",
		})
		.max(30, {
			message: "Username must be at most 30 characters long",
		})
		.regex(/^[a-zA-Z0-9](?!.*[-_.]{2})[a-zA-Z0-9._-]*[a-zA-Z0-9]$/, {
			message:
				"Username can contain letters, numbers, underscores (_), periods (.), and hyphens (-), but cannot start or end with a special character or have consecutive special characters.",
		}),
	emailVerifiedAt: z.string().optional().nullable(),
	provider: z.string().optional(),
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	aptosAddress: z.string().optional(),
	userMetadata: userMetadataSchema,
	bio: z
		.string()
		.max(1000, {
			message: "Bio must be at least 1000 characters long",
		})
		.optional(),
	headline: z
		.string()
		.max(160, {
			message: "Headline must be at least 160 characters long",
		})
		.optional(),
	totalExp: z.number().optional(),
	profilePictureUrl: z.string().optional(),
	profilePictureImage: z.string().optional(),
	bannerPictureUrl: z.string().optional(),
	bannerPicture: z.string().optional(),
	disabledAt: z.string().optional().nullable(),
	followerCount: z.number().optional(),
	followingCount: z.number().optional(),
	lastLoginAt: z.string().optional().nullable(),
	createdAt: z.string().optional(),
	updatedAt: z.string().optional(),
});

export type User = z.infer<typeof userSchema>;
