import AuthModal from "@/components/auth-modal.tsx";
import BlockchainSelection from "@/components/blockchain-selection.tsx";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { useModalControl } from "@/components/core/modals/use-modal-controller.ts";
import { useModal } from "@/components/core/modals/use-modal.ts";
import LoginSocialsModal from "@/components/login-socials-modal.tsx";
import { IconWallet } from "@/components/mvicons";

export default function WalletOptionsModal() {
	const { close } = useModalControl();
	const modal = useModal();

	return (
		<div className="flex flex-col justify-center items-center w-full mt-24">
			<div className="flex flex-col w-full max-w-[380px] gap-8">
				<div className="flex flex-col justify-center items-center w-full gap-4">
					<div className="inline-flex flex-row items-center gap-4 text-white">
						<IconWallet size={28} />
						<span className="text-xl text-white">Connect your Wallet</span>
					</div>
					<div className="flex flex-col justify-center items-center w-full">
						<span className="font-quagmire font-bold text-2xl text-white uppercase leading-8">
							Connect Accounts
						</span>
						<span className="font-quagmire font-bold text-2xl text-mvred-600 uppercase  leading-8">
							Earn Rewards!
						</span>
					</div>
				</div>
				<div className="flex flex-col w-full divide-y divide-white/20">
					<BlockchainSelection />
					<div className="flex flex-col w-full gap-3">
						<div className="flex flex-col justify-center items-center w-full pt-4 pb-2">
							<span className="text-md text-white leading-tight">
								No Wallet? No worries
							</span>
							<span className="text-md text-white/70 leading-tight">
								Login with these options:
							</span>
						</div>
						<Button
							onClick={() => {
								close();

								modal.open({
									title: "Login Email Modal",
									component: <AuthModal />,
								});
							}}
							className="font-quagmire w-full uppercase"
							type="button"
							variant="outline"
							color="blue"
						>
							<ButtonLabel>Login with email</ButtonLabel>
						</Button>
						<Button
							onClick={() => {
								close();

								modal.open({
									title: "Login Wallet Modal",
									component: <LoginSocialsModal />,
								});
							}}
							className="font-quagmire w-full uppercase"
							type="button"
							variant="outline"
							color="blue"
						>
							<ButtonLabel>Login with socials</ButtonLabel>
						</Button>
						<Button
							onClick={() => {
								close();
							}}
							className="w-full"
							type="button"
							variant="unstyled"
							size="sm"
						>
							<ButtonLabel>Not Now</ButtonLabel>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
