import rewardService from "@/api/services/reward-service.ts";
import { queryClient } from "@/query-client.ts";
import { queryOptions, useMutation } from "@tanstack/react-query";

export const rewardQueryOptions = () => {
	return queryOptions({
		queryKey: ["rewards"],
		queryFn: () => rewardService.getRewardStatus(),
	});
};

export const useRewardClaimMutation = () => {
	return useMutation({
		mutationKey: ["claim_rewards"],
		mutationFn: rewardService.claimReward,
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ["rewards"] });
			queryClient.invalidateQueries({ queryKey: ["profile"] });
		},
	});
};
