import {
	IconChevronRight,
	IconCollectionPair,
	IconCollectionSecure,
	IconCollectionSecured,
	IconCollections,
	IconPlus,
	IconQrcode,
} from "@/components/mvicons";
import Reader from "@/components/reader/reader.tsx";
import Scan from "@/components/scanner/scanner.tsx";
import CollectionsSkeleton from "@/components/skeletons/collections-skeleton";
import { useScreenWidth } from "@/hooks/use-screen-width.ts";
import type { Collection } from "@/types/collection.ts";
import { getAssetByCollectionName } from "@/utils/asset-url.ts";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { Suspense, useState } from "react";
import Masonry from "react-masonry-css";

function ItemPlaceholder({
	onClick,
}: {
	onClick?: () => void;
}) {
	return (
		<div className="relative flex grow-0 w-auto max-w-[260px] md:max-w-[180px]">
			<button
				onClick={onClick}
				type="button"
				className="z-[2] absolute inset-0 flex flex-1 shrink-0 flex-col justify-center items-center w-full h-full rounded-xl gap-4 p-2 md:p-4 bg-white bg-opacity-5 hover:bg-opacity-10 border-2 border-white/20 border-dashed transition-all"
			>
				<IconQrcode className="text-white" size={32} />
				<span className="max-w-[180px] text-center text-xs sm:text-sm font-semibold text-white leading-tight">
					Scan QR Code and redeem digital twin and rewards!
				</span>
			</button>
			<img
				className="z-[1] opacity-0 block w-full h-auto"
				src="/chills1.jpg"
				alt=""
			/>
		</div>
	);
}

function GuidelinePlaceholder({ className }: { className?: string }) {
	return (
		<div
			className={clsx(
				"flex flex-row min-w-[320px] w-full rounded-xl gap-4 p-8 bg-white bg-opacity-5",
				className,
			)}
		>
			<div className="inline-flex text-white">
				<IconCollections size={38} />
			</div>
			<div className="flex flex-col w-full gap-3">
				<span className="text-sm font-semibold text-white leading-tight">
					Your Grails and Prizes
				</span>
				<span className="text-sm text-white/70 leading-tight line-clamp-4">
					As you collect comics your collection will grow here. Over time you’ll
					receive additional collectibles, bonus content and rewards and any of
					those that are collectible will also show up here.
				</span>
				<span className="text-sm text-white/70 leading-tight line-clamp-3">
					This is your personal collection. Each item is yours and all kinds of
					awesome will land here as you explore the Macroverse and participate
					across it.
				</span>
			</div>
		</div>
	);
}

type CollectionButtonProps = {
	children: React.ReactNode;
	className?: string;
	icon: React.ReactNode;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
	onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
};

function CollectionButton({
	children,
	className,
	icon,
	onClick,
	onMouseEnter,
	onMouseLeave,
}: CollectionButtonProps) {
	const [isHovered, setIsHovered] = useState(false);
	const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
		setIsHovered(true);
		if (onMouseEnter) onMouseEnter(e);
	};
	const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
		setIsHovered(false);
		if (onMouseLeave) onMouseLeave(e);
	};

	return (
		<AnimatePresence mode="wait">
			<motion.button
				type="button"
				className={clsx(
					"inline-flex justify-center items-center rounded-md bg-white/20 backdrop-blur text-white transition h-[44px]",
					className,
				)}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={onClick}
				animate={{ width: isHovered ? "auto" : "44px" }}
				transition={{ duration: 0.3, ease: "easeOut" }}
			>
				<div className="inline-flex flex-row justify-center items-center p-2 gap-1.5 overflow-hidden">
					{icon}
					<AnimatePresence>
						{isHovered && (
							<motion.span
								className="font-quagmire inline-flex font-bold text-md uppercase"
								initial={{ opacity: 0, width: 0 }}
								animate={{ opacity: 1, width: "auto" }}
								exit={{ opacity: 0, width: 0 }}
								transition={{ duration: 0.3, ease: "easeOut" }}
							>
								{children}
							</motion.span>
						)}
					</AnimatePresence>
				</div>
			</motion.button>
		</AnimatePresence>
	);
}

export function Collections({
	user,
	collections,
	showAllItems,
}: {
	user?: User;
	collections: Collection[];
	showAllItems: boolean;
}) {
	// This is just to simulate the counters, make sure your collection has atleast 3 items in the database
	// visit the macroverse-admin to access this module, just replace the second parameter in slice
	const [isScanOpen, setScanOpen] = useState(false);
	const [isReaderOpen, setReaderOpen] = useState(false);
	const screenWidth = useScreenWidth();

	const isMobile = screenWidth < 640;
	const isTablet = screenWidth > 640 && screenWidth < 768;

	const getSlotCount = () => {
		if (screenWidth < 640) return 3; // Mobile
		if (screenWidth < 768) return 4; // Tablet
		return 5; // Desktop
	};

	const filteredItems = showAllItems
		? collections
		: collections?.filter((collection) => collection.isOwned);

	const slotCount = getSlotCount();
	const mtCol = filteredItems; // For Masonry
	const tCol = filteredItems.slice(0, slotCount); // For Dummy

	const notAvailableAnimation = {
		initial: { opacity: 0, scale: 0.7 },
		animate: { opacity: 1, scale: 1 },
		exit: { opacity: 0, scale: 0.7 },
		transition: { duration: 0.3 },
	};

	const breakpointColumnsObj = {
		default: 5,
		1024: 5,
		768: 4,
		640: 3,
	};

	const showMasonry =
		(isMobile && filteredItems.length >= 3) ||
		(isTablet && filteredItems.length >= 4) ||
		(!isMobile && !isTablet && filteredItems.length >= 5);

	const [isSecured, setIsSecured] = useState(false);
	const handleSecureCollection = () => {
		setIsSecured((prevState) => !prevState);
		alert("Collection item secured!");
	};

	const handleOpenReader = () => {
		setReaderOpen(true);
		document.body.style.overflow = "hidden";
	};

	return (
		<Suspense fallback={<CollectionsSkeleton />}>
			{isReaderOpen && (
				<Reader isOpen={isReaderOpen} onClose={() => setReaderOpen(false)} />
			)}

			{isScanOpen && (
				<Scan
					isAuth={user}
					isOpen={isScanOpen}
					onClose={() => setScanOpen(false)}
				/>
			)}

			{showMasonry ? (
				<div className="flex flex-col w-full">
					<AnimatePresence>
						<Masonry
							breakpointCols={breakpointColumnsObj}
							className="my-masonry-grid"
							columnClassName="my-masonry-grid_column"
						>
							{mtCol.map((filteredItem) => (
								<motion.div
									key={filteredItem.id}
									className={`relative group inline-flex bg-white bg-opacity-5 rounded-xl ${filteredItem.isOwned ? "grayscale-0" : "grayscale"}`}
									initial={
										filteredItem.isOwned
											? undefined
											: notAvailableAnimation.initial
									}
									animate={
										filteredItem.isOwned
											? undefined
											: notAvailableAnimation.animate
									}
									exit={
										filteredItem.isOwned
											? undefined
											: notAvailableAnimation.exit
									}
									transition={
										filteredItem.isOwned
											? undefined
											: notAvailableAnimation.transition
									}
									layout
								>
									<div className="z-[2] absolute inset-0 flex flex-col w-full h-full p-2 gap-2 bg-black/50 transition-all opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
										{filteredItem.isOwned ? (
											<>
												{isSecured ? (
													<>
														<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
															<IconCollectionSecured size={14} />
															<span className="text-xs font-semibold uppercase">
																Secured
															</span>
														</div>
														<CollectionButton
															onClick={handleOpenReader}
															className="self-end justify-end mt-auto ml-auto"
															icon={<IconChevronRight size={32} />}
														>
															Read
														</CollectionButton>
													</>
												) : (
													<>
														<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
															<IconCollectionPair size={14} />
															<span className="text-xs font-semibold uppercase">
																Paired
															</span>
														</div>
														<CollectionButton
															onClick={handleSecureCollection}
															className="self-end justify-end mt-auto ml-auto"
															icon={<IconCollectionSecure size={32} />}
														>
															Secure
														</CollectionButton>
													</>
												)}
											</>
										) : (
											<>
												<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
													<IconPlus size={isMobile ? 10 : 12} />
													<span className="text-[10px] sm:text-xs font-semibold uppercase">
														Available Now
													</span>
												</div>
												<CollectionButton
													onClick={() => setScanOpen(true)}
													className="self-end justify-end mt-auto ml-auto"
													icon={<IconQrcode size={32} />}
												>
													Pair
												</CollectionButton>
											</>
										)}
									</div>

									{filteredItem.assets && filteredItem.assets?.length !== 0 && (
										<img
											className="z-[1] opacity-100 block w-full h-auto"
											src={
												getAssetByCollectionName(
													filteredItem.assets,
													"cover_image",
												) as string
											}
											alt="Cover"
										/>
									)}
								</motion.div>
							))}
						</Masonry>
					</AnimatePresence>
				</div>
			) : (
				<div className="flex flex-col w-full gap-[4px]">
					<div className="flex flex-row w-full gap-[4px]">
						{tCol.map((filteredItem) => (
							<div
								key={filteredItem.id}
								className={clsx(
									"relative group flex grow-0 w-auto max-w-[260px] md:max-w-[180px] bg-white bg-opacity-5 rounded-xl",
									[filteredItem.isOwned ? "grayscale-0" : "grayscale"],
								)}
							>
								<div className="z-[2] absolute inset-0 flex flex-col w-full h-full p-2 gap-2 bg-black/50 transition-all opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
									{filteredItem.isOwned ? (
										<>
											{isSecured ? (
												<>
													<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
														<IconCollectionSecured size={14} />
														<span className="text-xs font-semibold uppercase">
															Secured
														</span>
													</div>
													<CollectionButton
														onClick={handleOpenReader}
														className="self-end justify-end mt-auto ml-auto"
														icon={<IconChevronRight size={16} stroke={8} />}
													>
														Read
													</CollectionButton>
												</>
											) : (
												<>
													<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
														<IconCollectionPair size={14} />
														<span className="text-xs font-semibold uppercase">
															Paired
														</span>
													</div>
													<CollectionButton
														onClick={handleSecureCollection}
														className="self-end justify-end mt-auto ml-auto"
														icon={<IconCollectionSecure size={32} />}
													>
														Secure
													</CollectionButton>
												</>
											)}
										</>
									) : (
										<>
											<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
												<IconPlus size={12} />
												<span className="text-xs font-semibold uppercase">
													Available Now
												</span>
											</div>
											<CollectionButton
												onClick={() => setScanOpen(true)}
												className="self-end justify-end mt-auto ml-auto"
												icon={<IconQrcode size={32} />}
											>
												Pair
											</CollectionButton>
										</>
									)}
								</div>

								{filteredItem.assets && filteredItem.assets?.length !== 0 && (
									<img
										className="z-[1] opacity-100 block w-full h-auto"
										src={
											getAssetByCollectionName(
												filteredItem.assets,
												"cover_image",
											) as string
										}
										alt={"FOO"}
									/>
								)}
							</div>
						))}

						{Array.from({ length: slotCount }).map((_, index) => {
							const placeholderCount = slotCount - tCol.length;

							if (
								index <
								placeholderCount -
									(!isMobile && !isTablet && tCol.length <= 1 ? 3 : 0)
							) {
								return (
									<ItemPlaceholder
										onClick={() => {
											setScanOpen(true);
										}}
										key={`placeholder-${index}`}
									/>
								);
							}

							if (tCol.length <= 1 && index === placeholderCount - 3) {
								return <GuidelinePlaceholder key="guideline" />;
							}

							return null;
						})}
					</div>

					<div className="flex md:hidden w-full">
						<GuidelinePlaceholder key="guideline" />
					</div>
				</div>
			)}
		</Suspense>
	);
}
