import { useProfileBannerMutation } from "@/api/queries/profile-queries.ts";
import BannerCropper from "@/components/banner-cropper.tsx";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { useModalControl } from "@/components/core/modals/use-modal-controller.ts";
import type { User } from "@/types/user.ts";
import getCroppedImg from "@/utils/get-cropped-image.ts";
import { type ChangeEvent, useState } from "react";
import type { Area } from "react-easy-crop";

export default function ChangeBannerImageModal({ profile }: { profile: User }) {
	const { close } = useModalControl();
	const [image, setImage] = useState<string | ArrayBuffer | null>(
		profile.bannerPictureUrl || "/profile-default-banner-1.jpg",
	);
	const [croppedImage, setCroppedImage] = useState<Area | null>(null);
	const profileBannerMutation = useProfileBannerMutation();

	const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target?.files;

		if (files?.length) {
			const reader = new FileReader();

			reader.onloadend = () => {
				setImage(reader.result);
			};

			reader.readAsDataURL(files[0]);
		}
	};

	const handleCroppedImage = async () => {
		try {
			const imageBlob = await getCroppedImg(image, croppedImage);

			await profileBannerMutation.mutateAsync({ image: imageBlob });

			close();
		} catch (e) {}
	};

	return (
		<div className="flex flex-col justify-center items-center w-full">
			<div className="flex flex-col w-full max-w-[380px] gap-8">
				<BannerCropper
					image={image}
					onCrop={(value) => {
						setCroppedImage(value);
					}}
				/>

				<div className="flex flex-col justify-center items-center w-full gap-4 pt-4">
					<p className="font-semibold text-white leading-tight">
						Adjust the size and placement of your Profile Banner.
					</p>
					<p className="text-white/70 leading-tight">
						When it's perfect, update it and jump back into the Macroverse...
					</p>
				</div>

				<div className="flex flex-col w-full gap-3">
					<Button
						className="relative font-quagmire uppercase w-full"
						type="button"
						variant="outline"
						color="blue"
					>
						<input
							id="profile-banner"
							type="file"
							accept="image/*"
							onChange={handleFileSelect}
							className="z-[2] absolute w-full h-full opacity-0"
						/>
						<ButtonLabel>Upload Image</ButtonLabel>
					</Button>
					<Button
						onClick={handleCroppedImage}
						className="font-quagmire uppercase self-center bg-mvdark-950 w-full"
						variant="gradient"
					>
						<ButtonLabel>Update</ButtonLabel>
					</Button>
					<Button
						onClick={() => {
							close();
						}}
						className="w-full"
						type="button"
						variant="unstyled"
						size="sm"
					>
						<ButtonLabel>Cancel</ButtonLabel>
					</Button>
				</div>
			</div>
		</div>
	);
}
