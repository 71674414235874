import { activityQueryOptions } from "@/api/queries/activity-queries.ts";
import { profileQueryOptions } from "@/api/queries/profile-queries.ts";
import { Avatar, AvatarImage } from "@/components/core/display/avatar.tsx";
import {
	IconActivity,
	IconCheckCircle,
	IconExp,
	IconUser,
} from "@/components/mvicons";
import type { Activity } from "@/types/activity.ts";
import type { User } from "@/types/user.ts";
import { humanReadableDateTime } from "@/utils/human-readable-datetime.ts";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_profile/activities")({
	loader: ({ context: { queryClient } }) => {
		return queryClient.ensureQueryData(activityQueryOptions());
	},
	component: Page,
});

const iconMap: Record<string, React.ReactNode> = {
	xp: <IconExp className="translate-y-[2px] mr-1" size={16} />,
	quest: <IconUser className="translate-y-[2px] mr-1" size={16} />,
	user: <IconCheckCircle className="translate-y-[2px] mr-1" size={16} />,
};

function parseTemplate(template: string, variables: Record<string, any>) {
	return template.split(/({\w+})/).map((part, index) => {
		if (part.startsWith("{") && part.endsWith("}")) {
			const key = part.slice(1, -1); // Extract the key inside {}
			if (key === "subject") {
				// Render the highlighted part
				return (
					<span key={index} className="inline mx-2 text-white">
						{iconMap[variables.icon]} {variables.text}
					</span>
				);
			}
			// For other placeholders, render the variable value
			return <span key={index}>{variables[key]}</span>;
		}

		// Render plain text (including text after placeholders)
		return part.trim() ? <span key={index}>{part}</span> : null;
	});
}

function ActivityItem({
	activity,
	profile,
}: { activity: Activity; profile: User }) {
	const { template, variables } = activity.metadata;

	return (
		<div className="flex flex-row items-center w-full py-3 gap-3">
			<Avatar className="self-start shrink-0 w-[40px] h-[40px] rounded-full border-2 border-white bg-mvlogo-gradient">
				<AvatarImage src={profile.profilePictureUrl} />
				{!profile.profilePictureUrl && (
					<AvatarImage src="/macronaut-avatar.png" />
				)}
			</Avatar>
			<div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full shrink grow gap-1 lg:gap-4">
				<p className="text-mvblue-300 leading-tight">
					{parseTemplate(template, variables)}
					{/*{activity.logType}*/}
				</p>
				<span className="text-xs text-white/30  leading-tight">
					{humanReadableDateTime(activity.createdAt)}
				</span>
			</div>
		</div>
	);
}

function Page() {
	const profileQuery = useSuspenseQuery(profileQueryOptions());
	const profile = profileQuery.data;
	const activityQuery = useSuspenseQuery(activityQueryOptions());
	const activities = activityQuery.data;

	return (
		<div className="flex flex-col w-full gap-6">
			<div className="flex flex-col w-full gap-2">
				<div className="flex flex-row items-center gap-2">
					<span className="text-sm font-semibold text-white">
						Real Time Updates
					</span>
				</div>
				<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
					<div className="inline-flex text-white">
						<IconActivity size={38} />
					</div>
					<div className="flex flex-col w-full gap-2">
						<span className="text-sm font-semibold text-white leading-tight">
							Traveling the ‘verse.
						</span>
						<span className="text-sm text-white/70 leading-tight">
							As you explore, engage, participate and collect you can see a
							record of all your travels here. Sooo handy when you need to find
							that perfect thing from last Tuesday.
						</span>
					</div>
				</div>
				{activities.items.length !== 0 && (
					<div className="flex flex-col w-full rounded-xl divide-y divide-white/10 py-3 px-6 bg-white bg-opacity-5">
						{activities?.items.map((activity) => (
							<ActivityItem
								key={activity.id}
								activity={activity}
								profile={profile}
							/>
						))}
						{/*<div className="flex flex-row items-center w-full py-3 gap-3">*/}
						{/*	<Avatar className="self-start shrink-0 w-[40px] h-[40px] rounded-full border-2 border-white bg-mvlogo-gradient">*/}
						{/*		<AvatarImage src="/macronaut-avatar.png" />*/}
						{/*		<AvatarFallback>AA</AvatarFallback>*/}
						{/*	</Avatar>*/}
						{/*	<div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full shrink grow gap-1 lg:gap-4">*/}
						{/*		<p className="text-mvblue-300 leading-tight">*/}
						{/*			You finished*/}
						{/*			<span className="inline mx-2 text-white">*/}
						{/*				<IconUser className="translate-y-[2px] mr-1" size={16} />*/}
						{/*				Complete your profile*/}
						{/*			</span>{" "}*/}
						{/*			Quest*/}
						{/*		</p>*/}
						{/*		<span className="text-xs text-white/30  leading-tight">*/}
						{/*			2 hours ago*/}
						{/*		</span>*/}
						{/*	</div>*/}
						{/*</div>*/}
						{/*<div className="flex flex-row items-center w-full py-3 gap-3">
							<Avatar className="self-start shrink-0 w-[40px] h-[40px] rounded-full border-2 border-white bg-mvlogo-gradient">
								<AvatarImage src="/macronaut-avatar.png" />
								<AvatarFallback>AA</AvatarFallback>
							</Avatar>
							<div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full shrink grow gap-1 lg:gap-4">
								<p className="text-mvblue-300 leading-tight">
									You started{" "}
									<span className="inline mx-2 text-white">
										<IconCheckCircle
											className="translate-y-[2px] mr-1"
											size={16}
										/>
										Following
									</span>{" "}
									@macroboy88
								</p>
								<span className="text-xs text-white/30 leading-tight">
									4 days ago
								</span>
							</div>
						</div>*/}
					</div>
				)}
			</div>
		</div>
	);
}
