import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { useModal } from "@/components/core/modals/use-modal.ts";
import {
	IconInstagram,
	IconKickstarter,
	IconXtwitter,
} from "@/components/mvicons";
import SupportModal from "@/components/support-modal.tsx";
import WaitlistModal from "@/components/waitlist-modal.tsx";
import { useAuth } from "@/hooks/use-auth.tsx";
import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { toast } from "sonner";

export const Route = createFileRoute("/privacy-policy")({
	component: PrivacyPolicy,
});

const callbackUrl =
	import.meta.env.VITE_PUBLIC_AUTH_CALLBACK_URL ||
	"https://www.macroverse.com/overview";

function PrivacyPolicy() {
	const modal = useModal();
	const isProduction = import.meta.env.VITE_PUBLIC_ENV === "production";
	const { authorize } = useAuth();
	const handleLogin = async () => {
		try {
			const response = await authorize({
				callbackUrl,
			});

			window.location.href = response.url;
		} catch (e) {
			toast.error("Unable to communicate with authorization server");
		}
	};

	return (
		<div
			className={clsx(
				"font-aller relative flex flex-col w-full bg-mvdark-950",
				isProduction ? "min-h-[100svh]" : "min-h-[calc(100svh-2rem)]",
			)}
		>
			<div
				className={clsx(
					"z-[3] fixed left-0 right-0 flex flex-row items-center justify-between w-full gap-4 p-4 md:p-6 bg-gradient-to-b from-mvdark-950/80 to-transparent",
					isProduction ? "top-0" : "top-8",
				)}
			>
				<div className="inline-flex h-[44px]">
					<img src="/svg/macroverse-logo-white.svg" alt="Macroverse" />
				</div>
				<div className="inline-flex flex-row w-auto gap-2">
					{isProduction ? (
						<Button
							className="font-quagmire backdrop-blur uppercase"
							onClick={() => {
								modal.open({
									title: "Waitlist Modal",
									component: <WaitlistModal />,
								});
							}}
							type="button"
							variant="gradient"
						>
							<ButtonLabel>Join Waitlist</ButtonLabel>
						</Button>
					) : (
						<Button
							className="font-quagmire backdrop-blur uppercase"
							onClick={handleLogin}
							type="button"
							variant="gradient"
						>
							<ButtonLabel>Login</ButtonLabel>
						</Button>
					)}
				</div>
			</div>

			<div className="z-[2] flex flex-col w-full max-w-[960px] gap-8 sm:gap-12 pt-24 pb-12 sm:py-24 px-12 mx-auto">
				<div className="flex flex-col w-auto gap-12">
					<div className="flex flex-col w-full gap-8">
						<div className="flex flex-col sm:flex-row justify-between items-start sm:items-end w-full sm:gap-2">
							<span className="text-lg font-semibold text-white">
								Macroverse Privacy Policy
							</span>
							<span className="text-xs text-white/70">
								Effective Date: December 20, 2024
							</span>
						</div>
						<p className="text-md text-white/70">
							Welcome to Macroverse! We are committed to protecting your
							privacy. This Privacy Policy outlines how we collect, use, and
							safeguard your information when you use our website, apps, or
							other services.
						</p>
					</div>

					<hr className="border-b border-white/20" />

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							1. Information We Collect
						</span>
						<p className="text-white/70">
							To provide you with the best experience, we collect the following
							types of information:
						</p>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							1.1. Personal Information
						</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>Your name, email address, username, and account details.</li>
							<li>
								Payment information, handled securely by trusted third-party
								services.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							1.2. Non-Personal Information
						</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								Details about your device, such as the type of browser or
								operating system.
							</li>
							<li>
								Your IP address and activity on the platform, which help us
								improve our services.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							1.3. Authentication and Access
						</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								Data from third-party login services (e.g., Google or Apple
								sign-in).
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							1.4. User-Generated Content
						</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								Content you upload or share on the platform, such as comments,
								fan art, or other materials.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							2. How We Use Your Information
						</span>
						<p className="text-white/70">
							We use the information we collect to:
						</p>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>Operate the platform efficiently.</li>
							<li>Process payments securely.</li>
							<li>
								Personalize your experience and enhance platform features.
							</li>
							<li>Send updates, promotions, and important notifications.</li>
							<li>Comply with legal obligations.</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							3. Sharing Your Information
						</span>
						<p className="text-white/70">
							We do not sell your personal information. However, we may share it
							in the following cases:
						</p>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								<span className="font-bold text-white">Service Providers</span>:
								With trusted companies that assist with hosting, payments, or
								analytics.
							</li>
							<li>
								<span className="font-bold text-white">Legal Compliance</span>:
								When required by law or necessary to protect our rights.
							</li>
							<li>
								<span className="font-bold text-white">Public Features</span>:
								Certain public information, like your username or profile
								picture, may be visible to others on the platform.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							4. Cookies and Tracking
						</span>
						<p className="text-white/70">
							We use cookies and similar tools to:
						</p>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>Enhance your experience on the platform.</li>
							<li>Analyze platform usage and improve functionality.</li>
							<li>Simplify account login and management.</li>
						</ul>
						<p className="text-white/70">
							You can adjust your cookie preferences in your browser settings.
						</p>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							5. Data Retention
						</span>
						<p className="text-white/70">
							We retain your data as long as necessary to keep the platform
							functioning effectively. To protect your information, we employ
							advanced security measures, including encryption and regular
							audits.
						</p>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							6. Platform Use and Liability Disclaimer
						</span>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							6.1. Intended Use
						</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								Macroverse is{" "}
								<span className="font-bold text-white">
									not intended for children under 13
								</span>
								n, and we do not knowingly collect information from them.
							</li>
							<li>
								The platform does not offer parental controls. Use of the
								platform is at your own risk, and{" "}
								<span className="font-bold text-white">
									Macroverse is not responsible
								</span>{" "}
								for misuse, including by minors.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							6.2. Disclaimer of Liability
						</span>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								By using Macroverse, you acknowledge that the platform is
								provided “as is” and at your own risk.
							</li>
							<li>
								<span className="font-bold text-white">
									We make no guarantees
								</span>{" "}
								regarding the platform’s performance or suitability for your
								needs.
							</li>
							<li>
								Macroverse is not liable for any issues, losses, or damages
								caused by using the platform, as permitted by law.
							</li>
						</ul>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							7. Compliance with Privacy Laws
						</span>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							7.1. General Compliance
						</span>
						<p className="text-white/70">
							Macroverse adheres to privacy laws worldwide, including:
						</p>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>
								<span className="font-bold text-white">GDPR</span> For users in
								the European Union.
							</li>
							<li>
								<span className="font-bold text-white">CCPA</span> For users in
								California.
							</li>
						</ul>
						<p className="text-white/70">
							For privacy inquiries or concerns, contact us at{" "}
							<a
								href="mailto:support@macroverse.com"
								className="underline text-mvblue-300"
							>
								support@macroverse.com
							</a>
							.
						</p>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							7.2. Your Rights
						</span>
						<p className="text-white/70">
							Depending on your location, you may have certain rights,
							including:
						</p>
						<ul className="list-disc pl-8 space-y-4 text-white/70">
							<li>Accessing, correcting, or deleting your personal data.</li>
							<li>Requesting information about how your data is used.</li>
						</ul>
						<p className="text-white/70">
							To exercise these rights, email us at{" "}
							<a
								href="mailto:support@macroverse.com"
								className="underline text-mvblue-300"
							>
								support@macroverse.com
							</a>
							. We will respond promptly and in compliance with applicable
							regulations.
						</p>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							8. Changes to This Policy
						</span>
						<p className="text-white/70">
							We may revise this Privacy Policy periodically. The most recent
							version will always be available on our platform. By continuing to
							use Macroverse, you agree to the latest Privacy Policy and our
							Terms of Service.
						</p>
					</div>

					<div className="flex flex-col w-full gap-6">
						<span className="text-md font-semibold text-white">
							9. Contact Us
						</span>
						<p className="text-white/70">
							If you have any questions about this Privacy Policy or your
							privacy rights, please contact us:
							<br />
							<span className="font-bold text-white">Email</span>:{" "}
							<a
								href="mailto:support@macroverse.com"
								className="underline text-mvblue-300"
							>
								support@macroverse.com
							</a>
						</p>
					</div>
				</div>

				<div className="flex flex-col w-full py-4 px-4 md:px-6 gap-6 mt-auto">
					<div className="flex flex-col md:flex-row justify-center items-center w-full gap-6 md:gap-12">
						<div className="inline-flex flex-row items-center w-auto gap-6 md:gap-12">
							<div className="inline-flex w-[75px] mr-2">
								<img
									className="block h-auto w-full"
									src="/svg/macroverse-logo-colored-layered.svg"
									alt="Macroverse"
								/>
							</div>
							<a
								href="https://macroverse.onelink.me/VuAp/mvweb"
								className="group inline-flex p-[2px] rounded-xl bg-mvlogo-gradient shadow-lg"
							>
								<div className="relative inline-flex flex-row items-center w-auto p-2 pr-4 rounded-xl gap-2 bg-mvdark-600 overflow-hidden">
									<div className="z-[2] inline-flex w-[40px] h-[40px] rounded-lg shadow-lg bg-mvdark-900 overflow-hidden">
										<img src="/macroverse-app-icon.png" alt="" />
									</div>
									<div className="z-[2] flex flex-col w-auto">
										<span className="font-semibold text-sm text-white leading-4">
											Get the
										</span>
										<span className="font-quagmire font-bold text-md text-white uppercase leading-4">
											Macroverse App
										</span>
									</div>
									<div className="z-[1] absolute inset-0 w-full h-full bg-mvlogo-gradient opacity-0 transition-all group-hover:opacity-20 pointer-events-none" />
								</div>
							</a>
						</div>
						<div className="flex flex-row items-center w-auto gap-8">
							<div className="inline-flex flex-row items-center gap-4">
								<a
									href="https://www.instagram.com/macroversehq"
									className="font-semibold text-white"
								>
									<IconInstagram size={24} />
								</a>
								<a
									href="https://x.com/macroverse"
									className="font-semibold text-white"
								>
									<IconXtwitter size={24} />
								</a>
								<a
									href="https://www.kickstarter.com/profile/macroverse/created"
									className="font-semibold text-white"
								>
									<IconKickstarter size={24} />
								</a>
							</div>
						</div>
					</div>
					<div className="flex flex-col w-full gap-4 mb-2">
						<div className="flex flex-row flex-wrap justify-center w-full gap-x-4">
							<a
								href="/terms-of-use"
								className="text-sm text-white/70 hover:text-white"
							>
								Terms of Use
							</a>
							<a
								href="/privacy-policy"
								className="text-sm text-white/70 hover:text-white"
							>
								Privacy Policy
							</a>
							<button
								onClick={() => {
									modal.open({
										title: "Support Modal",
										component: <SupportModal />,
									});
								}}
								type="button"
								className="text-sm text-white/70 hover:text-white"
							>
								Support
							</button>
						</div>
						<div className="flex flex-row justify-center w-full">
							<span className="text-xs text-white/50">
								© 2024 Macroverse Media Inc. All Rights Reserve
							</span>
						</div>
					</div>
				</div>
			</div>

			<div className="z-[1] fixed error-page-gradient inset-0 blur-[25px] overflow-hidden" />
		</div>
	);
}
