import AuthModal from "@/components/auth-modal.tsx";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import {
	Form,
	FormField,
	FormGroup,
	FormItem,
	FormMessage,
} from "@/components/core/forms/form.tsx";
import { Input } from "@/components/core/forms/input.tsx";
import { useModalControl } from "@/components/core/modals/use-modal-controller.ts";
import { useModal } from "@/components/core/modals/use-modal.ts";
import ForgotPasswordModal from "@/components/forgot-password-modal.tsx";
import { IconWarningTriangle } from "@/components/mvicons";
import { useAuth } from "@/hooks/use-auth.tsx";
import { useValidationError } from "@/hooks/use-validation-errors.ts";
import { useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import Alert from "./core/feedbacks/alert";

export default function RegisterModal() {
	const navigate = useNavigate({
		from: "/",
	});
	const { close } = useModalControl();
	const { register } = useAuth();
	const [errors, setErrors, clearError, getFirstError] = useValidationError();
	const modal = useModal();
	const form = useForm({
		defaultValues: {
			username: "",
			password: "",
			password_confirmation: "",
		},
	});

	const handleSubmit = async (values: {
		username: string;
		password: string;
		password_confirmation: string;
	}) => {
		try {
			clearError();

			await register(values);

			await navigate({
				to: "/welcome",
			});

			close();
		} catch (e) {
			if (e instanceof AxiosError) {
				setErrors(e.response?.data);
			}
		}
	};

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<div className="flex flex-col justify-center items-center w-full">
					<div className="flex flex-col w-full max-w-[380px] gap-8">
						<div className="flex flex-col justify-center items-center w-full gap-4">
							<div className="inline-flex flex-row items-center gap-2">
								<span className="text-lg sm:text-xl text-white">Welcome</span>
								<div className="w-[38px] sm:w-[44px]">
									<img
										className="block w-full"
										src="/svg/profile-macronaut.svg"
										alt=""
									/>
								</div>
								<span className="text-lg sm:text-xl text-white">Macronaut</span>
							</div>
							<div className="flex flex-col justify-center items-center w-full gap-2">
								<span className="font-quagmire text-center font-bold text-2xl text-white uppercase leading-7">
									Suit up and Let's Go...
								</span>
								<span className="font-quagmire text-center font-bold text-2xl text-mvred-600 uppercase  leading-7">
									Adventure awaits!
								</span>
							</div>
						</div>

						{!!errors.length && (
							<Alert
								type="error"
								allowClose={false}
								icon={<IconWarningTriangle size={28} />}
							>
								{errors.length > 1 ? (
									<ul className="list-disc">
										{errors.map((error, index) => (
											<li key={index}>{error} FFF</li>
										))}
									</ul>
								) : (
									<>{getFirstError()}</>
								)}
							</Alert>
						)}

						<div className="flex flex-col w-full divide-y divide-white/20">
							<div className="flex flex-col w-full gap-4 pb-6">
								<div className="grid grid-cols-12 w-full gap-3">
									<div className="col-span-12 flex flex-col w-full">
										<FormField
											control={form.control}
											name="username"
											render={({ field }) => (
												<FormItem>
													<FormGroup inputSize="lg">
														<Input
															type="email"
															autoComplete="off"
															placeholder="Email Address"
															{...field}
														/>
													</FormGroup>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="col-span-12 flex flex-col w-full">
										<FormField
											control={form.control}
											name="password"
											render={({ field }) => (
												<FormItem>
													<FormGroup inputSize="lg">
														<Input
															type="password"
															placeholder="Password"
															{...field}
														/>
													</FormGroup>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="col-span-12 flex flex-col w-full">
										<FormField
											control={form.control}
											name="password_confirmation"
											render={({ field }) => (
												<FormItem>
													<FormGroup inputSize="lg">
														<Input
															type="password"
															placeholder="Re-type Password"
															{...field}
														/>
													</FormGroup>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="col-span-12 flex flex-col w-full px-8 mt-2 gap-2">
										<p className="text-center text-sm text-white/70">
											By signing up, I agree to the Macroverse{" "}
											<a
												target="_blank"
												rel="noreferrer"
												href="https://www.macroverse.world/terms"
												className="text-white"
											>
												Terms of use
											</a>{" "}
											and{" "}
											<a
												target="_blank"
												rel="noreferrer"
												href="https://www.macroverse.world/privacy"
												className="text-white"
											>
												Privacy Policy
											</a>
											.
										</p>
										<p className="text-center text-sm text-white/70">
											Macroverse collects and processes email address for
											marketing purposes. You can unsubscribe at any time.
										</p>
									</div>
									<div className="col-span-12 flex flex-col w-full mt-2">
										<Button
											isLoading={form.formState.isSubmitting}
											className="font-quagmire bg-mvdark-950 uppercase w-full"
											type="submit"
											variant="gradient"
											size="lg"
										>
											<ButtonLabel>Register</ButtonLabel>
										</Button>
									</div>
								</div>
								<div className="flex flex-row justify-center items-center w-full mt-2 divide-x divide-x-2 divide-white/20">
									<button
										onClick={() => {
											close();

											modal.open({
												title: "Login Modal",
												component: <AuthModal />,
											});
										}}
										type="button"
										className="text-md text-white hover:text-mvblue-300 px-4 leading-4"
									>
										Login
									</button>
									<button
										onClick={() => {
											close();

											modal.open({
												title: "Forgot Password Modal",
												component: <ForgotPasswordModal />,
											});
										}}
										type="button"
										className="text-md text-white hover:text-mvblue-300 px-4 leading-4"
									>
										Forgot Password
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</Form>
	);
}
