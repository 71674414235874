import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";
import clsx from "clsx";

interface XpEngineProps {
	className?: string;
}

// Wrapper component to isolate useRive logic that
// renders the <RiveComponent />
const XpEngine = ({ className }: XpEngineProps) => {
	const { RiveComponent } = useRive({
		// src: "/animations/rubick.riv",
		// src: "/animations/skins_demo.riv",
		src: "/animations/xpengine.riv",
		stateMachines: "State Machine 1",
		autoplay: true,
		layout: new Layout({
			fit: Fit.Contain, // Adjust how the animation fits within its container
			alignment: Alignment.Center, // Adjust alignment within the container
		}),
	});

	return (
		<div className={clsx("", className)}>
			<RiveComponent />
		</div>
	);
};

export default XpEngine;
