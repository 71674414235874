import Skeleton from "./skeleton";

export default function CollectionsSkeleton() {
	return (
		<div className="flex flex-row w-full gap-1">
			<Skeleton
				className="hidden sm:inline-flex w-full h-[200px] sm:h-[230px] md:h-[260px] rounded-xl"
				animate
			/>
			<Skeleton
				className="hidden md:inline-flex w-full h-[200px] sm:h-[230px] md:h-[260px] rounded-xl"
				animate
			/>
			<Skeleton
				className="w-full h-[200px] sm:h-[230px] md:h-[260px] rounded-xl"
				animate
			/>
			<Skeleton
				className="w-full h-[200px] sm:h-[230px] md:h-[260px] rounded-xl"
				animate
			/>
			<Skeleton
				className="w-full h-[200px] sm:h-[230px] md:h-[260px] rounded-xl"
				animate
			/>
		</div>
	);
}
