import { Button, ButtonLabel } from "@/components/core/buttons/button";
import CustomToast from "@/components/core/feedbacks/custom-toast.tsx";
import { useModal } from "@/components/core/modals/use-modal.ts";
import ExternalLinkModal from "@/components/external-link-modal.tsx";
import { IconUser } from "@/components/mvicons";
import RewardModal from "@/components/reward-modal.tsx";
import WalletOptionsModal from "@/components/wallet-options-modal.tsx";
import { createFileRoute } from "@tanstack/react-router";
import { toast } from "sonner";

export const Route = createFileRoute("/guides")({
	component: GuidesComponent,
});

function GuidesComponent() {
	const modal = useModal();

	return (
		<div className="flex flex-col w-full min-h-screen bg-mvdark-950">
			<div className="flex self-center flex-col w-full max-w-[960px] gap-4 p-12">
				<div className="flex flex-row w-full gap-2">
					<Button
						onClick={() => {
							toast(
								<CustomToast
									icon={<IconUser size={72} />}
									message="Profile Updated"
								/>,
								{
									duration: 5000,
									position: "top-center",
									className:
										"flex flex-row justify-center p-0 bg-transparent shadow-none !mt-[calc(50vh-65px)]",
								},
							);
						}}
					>
						<ButtonLabel>Sample Notif</ButtonLabel>
					</Button>
					<Button
						onClick={() => {
							modal.open({
								title: "Reward Modal",
								size: "auto",
								component: <RewardModal />,
							});
						}}
					>
						<ButtonLabel>Rewards Highlight</ButtonLabel>
					</Button>
				</div>
				<div className="flex flex-row w-full gap-2">
					<Button
						onClick={() => {
							modal.open({
								title: "Wallet Options Modal",
								component: <ExternalLinkModal />,
							});
						}}
						type="button"
						className=""
						variant="default"
						color="default"
					>
						<ButtonLabel>External Link Modal</ButtonLabel>
					</Button>
				</div>
				<div className="flex flex-row w-full gap-2">
					<Button
						onClick={() => {
							modal.open({
								title: "Wallet Options Modal",
								decor: "/svg/decor-account.svg",
								component: <WalletOptionsModal />,
							});
						}}
						type="button"
						className=""
						variant="default"
						color="default"
					>
						<ButtonLabel>Wallet Options</ButtonLabel>
					</Button>
				</div>
				<div className="flex flex-row w-full gap-2">
					<Button
						onClick={() => {
							modal.open({
								type: "confirm",
								variant: "warning",
								title: "Are you sure you want to proceed?",
								description:
									"This action cannot be undone and may have significant consequences. Please confirm that you understand the risks involved and wish to continue.",
								// onConfirm: async () => {
								//   await new Promise((resolve) => setTimeout(resolve, 2000));
								//   modal.closeModal(confirmModal);
								// },
							});
						}}
						type="button"
						className=""
						variant="default"
						color="default"
					>
						<ButtonLabel>Confirm Error</ButtonLabel>
					</Button>
					<Button
						onClick={() => {
							const confirmModal = modal.open({
								type: "confirm",
								variant: "success",
								title: "Are you sure you want to proceed?",
								description:
									"This action cannot be undone and may have significant consequences. Please confirm that you understand the risks involved and wish to continue.",
								// onConfirm: async () => {
								//   await new Promise((resolve) => setTimeout(resolve, 2000));
								//   modal.closeModal(confirmModal);
								// },
							});
						}}
						type="button"
						className=""
						variant="default"
						color="default"
					>
						<ButtonLabel>Confirm Success</ButtonLabel>
					</Button>
				</div>
				<div className="flex flex-row w-full gap-2">
					<Button size="xs" variant="default" color="default">
						Button Text
					</Button>
					<Button size="sm" variant="default" color="red">
						Button Text
					</Button>
					<Button size="md" variant="default" color="blue">
						Button Text
					</Button>
					<Button size="lg" variant="default">
						Button Text
					</Button>
				</div>
				<div className="flex flex-row w-full gap-2">
					<Button size="xs" variant="outline" color="default">
						Button Text
					</Button>
					<Button size="sm" variant="outline" color="red">
						Button Text
					</Button>
					<Button size="md" variant="outline" color="blue">
						Button Text
					</Button>
					<Button size="lg" variant="outline">
						Button Text
					</Button>
				</div>
				<div className="flex flex-row w-full gap-2">
					<Button size="xs" variant="light" color="default">
						Button Text
					</Button>
					<Button size="sm" variant="light" color="red">
						Button Text
					</Button>
					<Button size="md" variant="light" color="blue">
						Button Text
					</Button>
					<Button size="lg" variant="light">
						Button Text
					</Button>
				</div>
				<div className="flex flex-row w-full gap-2">
					<Button size="xs" variant="gradient" color="default">
						Button Text
					</Button>
					<Button size="sm" variant="gradient" color="red">
						Button Text
					</Button>
					<Button size="md" variant="gradient" color="blue">
						Button Text
					</Button>
					<Button size="lg" variant="gradient">
						Button Text
					</Button>
				</div>
				<div className="flex flex-row w-full gap-2">
					<Button size="xs" variant="shadow" color="default">
						Button Text
					</Button>
					<Button size="sm" variant="shadow" color="red">
						Button Text
					</Button>
					<Button size="md" variant="shadow" color="blue">
						Button Text
					</Button>
					<Button size="lg" variant="shadow">
						Button Text
					</Button>
				</div>
				<div className="flex flex-row w-full gap-2">
					<Button size="xs" variant="unstyled" color="default">
						Button Text
					</Button>
					<Button size="sm" variant="unstyled" color="red">
						Button Text
					</Button>
					<Button size="md" variant="unstyled" color="blue">
						Button Text
					</Button>
					<Button size="lg" variant="unstyled">
						Button Text
					</Button>
				</div>
				<div className="flex flex-row w-full gap-2">
					<Button
						className="rounded-full"
						mode="icon"
						size="xs"
						variant="default"
						color="default"
					>
						A
					</Button>
					<Button
						className="rounded-full"
						mode="icon"
						size="sm"
						variant="default"
						color="red"
					>
						B
					</Button>
					<Button
						className="rounded-full"
						mode="icon"
						size="md"
						variant="default"
						color="blue"
					>
						C
					</Button>
					<Button
						className="rounded-full"
						mode="icon"
						size="lg"
						variant="default"
					>
						D
					</Button>
				</div>
				<div className="flex flex-row w-full gap-2">
					<Button size="xs" variant="default" color="default" disabled>
						Disabled
					</Button>
					<Button size="sm" variant="outline" color="red" disabled>
						Disabled
					</Button>
					<Button size="md" variant="light" color="blue" disabled>
						Disabled
					</Button>
					<Button size="lg" variant="light" color="blue" disabled>
						Disabled
					</Button>
				</div>
				<div className="flex flex-row w-full gap-2">
					<Button size="xs" variant="default" color="default" isLoading>
						Button Text
					</Button>
					<Button size="sm" variant="outline" color="red" isLoading>
						Button Text
					</Button>
					<Button size="md" variant="light" color="blue" isLoading>
						Button Text
					</Button>
					<Button size="lg" variant="light" color="blue" isLoading>
						Button Text
					</Button>
				</div>
			</div>
		</div>
	);
}
