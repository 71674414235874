import { useWaitlistMutation } from "@/api/queries/waitlist-queries.ts";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import Alert from "@/components/core/feedbacks/alert";
import {
	Form,
	FormField,
	FormGroup,
	FormItem,
	FormMessage,
} from "@/components/core/forms/form.tsx";
import { Input } from "@/components/core/forms/input.tsx";
import { useModalControl } from "@/components/core/modals/use-modal-controller.ts";
import { IconCheckCircle, IconMv } from "@/components/mvicons";
import { useAuth } from "@/hooks/use-auth.tsx";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
	email: z.string().email().min(1, {
		message: "Please enter a valid email",
	}),
});

export default function WaitlistModal() {
	const { csrf } = useAuth();
	const { close } = useModalControl();
	const waitlistMutation = useWaitlistMutation();
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: "",
		},
	});

	const handleSubmit = async (payload: z.infer<typeof formSchema>) => {
		await csrf();

		await waitlistMutation.mutateAsync(payload);
	};

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<div className="flex flex-col justify-center items-center w-full">
					{form.formState.isSubmitSuccessful ? (
						<div className="flex flex-col justify-center items-center w-full max-w-[380px] gap-6">
							<div className="flex flex-col justify-center items-center w-full gap-2">
								<div className="inline-flex flex-row items-center gap-2">
									<span className="text-lg sm:text-xl text-white">
										Congrats
									</span>
									<div className="w-[38px] sm:w-[44px]">
										<img
											className="block w-full"
											src="/svg/profile-macronaut.svg"
											alt=""
										/>
									</div>
									<span className="text-lg sm:text-xl text-white">
										Macronaut
									</span>
								</div>
								<span className="font-quagmire text-center uppercase font-bold text-2xl text-white leading-8">
									You're on the Radar!
								</span>
							</div>
							<Alert
								type="info"
								allowClose={false}
								title="Your signal has been received."
								icon={<IconCheckCircle size={28} />}
							>
								<p className="">
									The beacon will be in touch soon. Keep an ear out for the ping
									-{" "}
									<span className="font-bold">
										watch your email for the next step
									</span>{" "}
									on your journey into the Macroverse.
								</p>
							</Alert>
							<div className="grid grid-cols-12 w-full gap-3">
								<div className="col-span-12 flex flex-col w-full mt-2">
									<Button
										onClick={close}
										className="font-quagmire bg-mvdark-950 uppercase w-full"
										type="submit"
										variant="outline"
										color="blue"
										size="md"
									>
										<ButtonLabel>Close Transmission</ButtonLabel>
									</Button>
								</div>
							</div>
						</div>
					) : (
						<div className="flex flex-col justify-center items-center w-full max-w-[380px] gap-6">
							<div className="flex flex-col justify-center items-center w-full gap-3">
								<div className="inline-flex flex-row items-center w-auto gap-4 text-white">
									<IconMv size={28} />
									<span className="text-lg sm:text-xl text-white leading-tight">
										Discovery in Progress...
									</span>
								</div>
								<span className="font-quagmire text-center uppercase font-bold text-2xl text-white leading-8">
									You're Getting Close!
								</span>
							</div>
							<div className="flex flex-col justify-center items-center w-full gap-4">
								<p className="text-center text-sm text-white leading-6">
									Brave Macronaut, you're on the verge of unlocking the
									Macroverse. The gateway isn't open yet, but a{" "}
									<span className="font-semibold">
										beacon will soon respond with instructions
									</span>{" "}
									to guide you in.
								</p>
								<p className="text-center text-sm text-white leading-6">
									<span className="font-semibold">
										Join the waitlist now to ensure your place
									</span>{" "}
									among the first explorers to embark on this epic adventure.
								</p>
							</div>
							<div className="grid grid-cols-12 w-full gap-3">
								<div className="col-span-12 flex flex-col w-full">
									<FormField
										control={form.control}
										name="email"
										render={({ field }) => (
											<FormItem>
												<FormGroup>
													<Input
														autoComplete="off"
														placeholder="Email Address"
														{...field}
													/>
												</FormGroup>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
								<div className="col-span-12 flex flex-col w-full mt-2">
									<Button
										disabled={form.formState.isSubmitting}
										className="font-quagmire bg-mvdark-950 uppercase w-full"
										type="submit"
										variant="gradient"
										size="lg"
									>
										<ButtonLabel>Send Transmission</ButtonLabel>
									</Button>
								</div>
							</div>
						</div>
					)}
				</div>
			</form>
		</Form>
	);
}
