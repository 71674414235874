import { useProfileDismissWelcome } from "@/api/queries/profile-queries.ts";
import { questQueryOptions } from "@/api/queries/quest-queries.ts";
import SingleQuestCard from "@/components/single-quest-card.tsx";
import QuestCardSkeleton from "@/components/skeletons/quest-card-skeleton";
import { IconX } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import ReactDOM from "react-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function Welcome({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}) {
	const navigate = useNavigate({
		from: "/overview",
	});
	const questQuery = useQuery(
		questQueryOptions({
			tags: "onboarding",
		}),
	);
	const quests = questQuery.data;
	const isLoading = false;
	const isProduction = import.meta.env.VITE_PUBLIC_ENV === "production";

	return ReactDOM.createPortal(
		<AnimatePresence>
			{isOpen && (
				<motion.div
					className={clsx(
						"font-aller z-50 fixed right-0 bottom-0 left-0 flex items-center justify-center min-h-[100svh]",
						isProduction ? "top-0" : "top-8",
					)}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.1 }}
				>
					{/* overlay */}
					<motion.div
						className="absolute inset-0 bg-mvdark-950/60"
						initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
						animate={{ opacity: 1, backdropFilter: "blur(3px)" }}
						exit={{ opacity: 0, backdropFilter: "blur(0px)" }}
						transition={{ duration: 0.1 }}
					/>

					{/* main container */}
					<motion.div
						className="relative flex flex-col h-auto w-auto"
						initial={{ opacity: 0, scale: 0.95 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.95 }}
						transition={{ duration: 0.1 }}
					>
						{/* close button */}
						<button
							onClick={() => {
								onClose();
							}}
							type="button"
							className="z-10 absolute left-4 top-4 inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white"
						>
							<IconX size={28} stroke={2} />
						</button>

						{/* main content */}
						<motion.div className="relative flex flex-col justify-center items-center h-[100svh] md:h-[720px] lg:h-[900px] w-screen md:w-full md:max-w-[640px] lg:max-w-[1024px] rounded-none md:rounded-4xl border border-white/10 bg-mvdark-950 bg-opacity-95 shadow-xl overflow-hidden">
							<div className="flex flex-col w-full p-12 gap-8 overflow-auto">
								<div className="flex flex-col justify-center items-center w-full gap-4">
									<div className="inline-flex flex-row items-center gap-2 text-white">
										<span className="font-semibold text-lg sm:text-2xl text-white">
											Welcome
										</span>
										<div className="w-[36px] sm:w-[44px]">
											<img
												className="block w-full"
												src="/svg/profile-macronaut.svg"
												alt=""
											/>
										</div>
										<span className="font-semibold text-lg sm:text-2xl text-white">
											Macronaut
										</span>
									</div>
								</div>

								<div className="flex flex-col w-full gap-4">
									<p className="font-bold text-md text-white">
										Thanks for joining the mission!
									</p>
									<div className="flex flex-col w-full">
										<p className=" text-md text-white/50">
											Together, we&apos;re building a universe of storytelling
											and we can&apos;t do it without you!
										</p>
										<p className=" text-md text-white/50">
											Let&apos;s get you some rewards while you set-up your
											Macroverse account.
										</p>
									</div>
									<p className="font-bold text-md text-white">
										Where do you want to start?
									</p>
								</div>

								<div className="flex flex-row w-full shrink grow">
									<Swiper
										slidesPerView="auto"
										spaceBetween={10}
										pagination={{
											enabled: false,
											clickable: true,
										}}
										modules={[Pagination]}
										className="welcome-swiper flex w-full overflow-x-hidden"
									>
										{quests?.items?.map((quest) => (
											<SwiperSlide
												key={quest.id}
												className="flex min-w-[260px] w-[260px] max-w-[260px] min-h-[300px]"
											>
												{isLoading ? (
													<QuestCardSkeleton />
												) : (
													<SingleQuestCard
														quest={quest}
														onAction={async (quest) => {
															await navigate({
																to: quest.metadata.actionLink,
															});
														}}
														onClaim={async () => {
															// Uncomment and customize the navigation logic as needed
															// await navigate({
															//   to: "/profile",
															// });
														}}
													/>
												)}
											</SwiperSlide>
										))}
									</Swiper>
								</div>

								<div className="flex flex-col w-full gap-4">
									<p className="text-md text-white/50">
										We&apos;ll be adding a ton of exciting new content, features
										and creators over the next weeks and months and we look
										forward to sharing the adventure.
									</p>
									<p className="font-bold text-md text-white">
										Suit up and Let&apos;s Go!
									</p>
									<p className="font-bold italic text-md text-white">
										Eben, Adam & Ricci
									</p>
								</div>
							</div>
						</motion.div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>,
		document.body,
	);
}
