import type {
	ModalContextType,
	ModalProps,
} from "@/components/core/modals/types.ts";
import { createContext, useContext } from "react";

const ModalContext = createContext<ModalContextType>({
	state: { modals: [] },
	open: (_: ModalProps) => {
		return 0;
	},
	close: () => {},
});

const useModal = () => {
	const context = useContext(ModalContext);

	if (!context) {
		throw new Error("useModal must be used within a ModalProvider");
	}

	return context;
};

export { ModalContext, useModal };
