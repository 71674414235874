/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from "./routes/__root";
import { Route as TermsOfUseImport } from "./routes/terms-of-use";
import { Route as ScannerImport } from "./routes/scanner";
import { Route as ScanImport } from "./routes/scan";
import { Route as PrivacyPolicyImport } from "./routes/privacy-policy";
import { Route as GuidesImport } from "./routes/guides";
import { Route as Error405Import } from "./routes/error405";
import { Route as Error403Import } from "./routes/error403";
import { Route as ArchieImport } from "./routes/archie";
import { Route as ProfileImport } from "./routes/_profile";
import { Route as IndexImport } from "./routes/index";
import { Route as ProfileEditImport } from "./routes/profile.edit";
import { Route as AgamottoCodeImport } from "./routes/agamotto_.$code";
import { Route as ProfileUpdatesImport } from "./routes/_profile.updates";
import { Route as ProfileSettingsImport } from "./routes/_profile.settings";
import { Route as ProfileOverviewImport } from "./routes/_profile.overview";
import { Route as ProfileCollectionsImport } from "./routes/_profile.collections";
import { Route as ProfileBadgesImport } from "./routes/_profile.badges";
import { Route as ProfileActivitiesImport } from "./routes/_profile.activities";
import { Route as ProfileAboutImport } from "./routes/_profile.about";
import { Route as PublicUserUsernameImport } from "./routes/_public.user_.$username";
import { Route as PublicUserUsernameCollectionsImport } from "./routes/_public.user_.$username.collections";
import { Route as PublicUserUsernameActivitiesImport } from "./routes/_public.user_.$username.activities";
import { Route as PublicUserUsernameAboutImport } from "./routes/_public.user_.$username.about";

// Create/Update Routes

const TermsOfUseRoute = TermsOfUseImport.update({
  id: "/terms-of-use",
  path: "/terms-of-use",
  getParentRoute: () => rootRoute,
} as any);

const ScannerRoute = ScannerImport.update({
  id: "/scanner",
  path: "/scanner",
  getParentRoute: () => rootRoute,
} as any);

const ScanRoute = ScanImport.update({
  id: "/scan",
  path: "/scan",
  getParentRoute: () => rootRoute,
} as any);

const PrivacyPolicyRoute = PrivacyPolicyImport.update({
  id: "/privacy-policy",
  path: "/privacy-policy",
  getParentRoute: () => rootRoute,
} as any);

const GuidesRoute = GuidesImport.update({
  id: "/guides",
  path: "/guides",
  getParentRoute: () => rootRoute,
} as any);

const Error405Route = Error405Import.update({
  id: "/error405",
  path: "/error405",
  getParentRoute: () => rootRoute,
} as any);

const Error403Route = Error403Import.update({
  id: "/error403",
  path: "/error403",
  getParentRoute: () => rootRoute,
} as any);

const ArchieRoute = ArchieImport.update({
  id: "/archie",
  path: "/archie",
  getParentRoute: () => rootRoute,
} as any);

const ProfileRoute = ProfileImport.update({
  id: "/_profile",
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => rootRoute,
} as any);

const ProfileEditRoute = ProfileEditImport.update({
  id: "/profile/edit",
  path: "/profile/edit",
  getParentRoute: () => rootRoute,
} as any);

const AgamottoCodeRoute = AgamottoCodeImport.update({
  id: "/agamotto_/$code",
  path: "/agamotto/$code",
  getParentRoute: () => rootRoute,
} as any);

const ProfileUpdatesRoute = ProfileUpdatesImport.update({
  id: "/updates",
  path: "/updates",
  getParentRoute: () => ProfileRoute,
} as any);

const ProfileSettingsRoute = ProfileSettingsImport.update({
  id: "/settings",
  path: "/settings",
  getParentRoute: () => ProfileRoute,
} as any);

const ProfileOverviewRoute = ProfileOverviewImport.update({
  id: "/overview",
  path: "/overview",
  getParentRoute: () => ProfileRoute,
} as any);

const ProfileCollectionsRoute = ProfileCollectionsImport.update({
  id: "/collections",
  path: "/collections",
  getParentRoute: () => ProfileRoute,
} as any);

const ProfileBadgesRoute = ProfileBadgesImport.update({
  id: "/badges",
  path: "/badges",
  getParentRoute: () => ProfileRoute,
} as any);

const ProfileActivitiesRoute = ProfileActivitiesImport.update({
  id: "/activities",
  path: "/activities",
  getParentRoute: () => ProfileRoute,
} as any);

const ProfileAboutRoute = ProfileAboutImport.update({
  id: "/about",
  path: "/about",
  getParentRoute: () => ProfileRoute,
} as any);

const PublicUserUsernameRoute = PublicUserUsernameImport.update({
  id: "/_public/user_/$username",
  path: "/user/$username",
  getParentRoute: () => rootRoute,
} as any);

const PublicUserUsernameCollectionsRoute =
  PublicUserUsernameCollectionsImport.update({
    id: "/collections",
    path: "/collections",
    getParentRoute: () => PublicUserUsernameRoute,
  } as any);

const PublicUserUsernameActivitiesRoute =
  PublicUserUsernameActivitiesImport.update({
    id: "/activities",
    path: "/activities",
    getParentRoute: () => PublicUserUsernameRoute,
  } as any);

const PublicUserUsernameAboutRoute = PublicUserUsernameAboutImport.update({
  id: "/about",
  path: "/about",
  getParentRoute: () => PublicUserUsernameRoute,
} as any);

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/": {
      id: "/";
      path: "/";
      fullPath: "/";
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    "/_profile": {
      id: "/_profile";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof ProfileImport;
      parentRoute: typeof rootRoute;
    };
    "/archie": {
      id: "/archie";
      path: "/archie";
      fullPath: "/archie";
      preLoaderRoute: typeof ArchieImport;
      parentRoute: typeof rootRoute;
    };
    "/error403": {
      id: "/error403";
      path: "/error403";
      fullPath: "/error403";
      preLoaderRoute: typeof Error403Import;
      parentRoute: typeof rootRoute;
    };
    "/error405": {
      id: "/error405";
      path: "/error405";
      fullPath: "/error405";
      preLoaderRoute: typeof Error405Import;
      parentRoute: typeof rootRoute;
    };
    "/guides": {
      id: "/guides";
      path: "/guides";
      fullPath: "/guides";
      preLoaderRoute: typeof GuidesImport;
      parentRoute: typeof rootRoute;
    };
    "/privacy-policy": {
      id: "/privacy-policy";
      path: "/privacy-policy";
      fullPath: "/privacy-policy";
      preLoaderRoute: typeof PrivacyPolicyImport;
      parentRoute: typeof rootRoute;
    };
    "/scan": {
      id: "/scan";
      path: "/scan";
      fullPath: "/scan";
      preLoaderRoute: typeof ScanImport;
      parentRoute: typeof rootRoute;
    };
    "/scanner": {
      id: "/scanner";
      path: "/scanner";
      fullPath: "/scanner";
      preLoaderRoute: typeof ScannerImport;
      parentRoute: typeof rootRoute;
    };
    "/terms-of-use": {
      id: "/terms-of-use";
      path: "/terms-of-use";
      fullPath: "/terms-of-use";
      preLoaderRoute: typeof TermsOfUseImport;
      parentRoute: typeof rootRoute;
    };
    "/_profile/about": {
      id: "/_profile/about";
      path: "/about";
      fullPath: "/about";
      preLoaderRoute: typeof ProfileAboutImport;
      parentRoute: typeof ProfileImport;
    };
    "/_profile/activities": {
      id: "/_profile/activities";
      path: "/activities";
      fullPath: "/activities";
      preLoaderRoute: typeof ProfileActivitiesImport;
      parentRoute: typeof ProfileImport;
    };
    "/_profile/badges": {
      id: "/_profile/badges";
      path: "/badges";
      fullPath: "/badges";
      preLoaderRoute: typeof ProfileBadgesImport;
      parentRoute: typeof ProfileImport;
    };
    "/_profile/collections": {
      id: "/_profile/collections";
      path: "/collections";
      fullPath: "/collections";
      preLoaderRoute: typeof ProfileCollectionsImport;
      parentRoute: typeof ProfileImport;
    };
    "/_profile/overview": {
      id: "/_profile/overview";
      path: "/overview";
      fullPath: "/overview";
      preLoaderRoute: typeof ProfileOverviewImport;
      parentRoute: typeof ProfileImport;
    };
    "/_profile/settings": {
      id: "/_profile/settings";
      path: "/settings";
      fullPath: "/settings";
      preLoaderRoute: typeof ProfileSettingsImport;
      parentRoute: typeof ProfileImport;
    };
    "/_profile/updates": {
      id: "/_profile/updates";
      path: "/updates";
      fullPath: "/updates";
      preLoaderRoute: typeof ProfileUpdatesImport;
      parentRoute: typeof ProfileImport;
    };
    "/agamotto_/$code": {
      id: "/agamotto_/$code";
      path: "/agamotto/$code";
      fullPath: "/agamotto/$code";
      preLoaderRoute: typeof AgamottoCodeImport;
      parentRoute: typeof rootRoute;
    };
    "/profile/edit": {
      id: "/profile/edit";
      path: "/profile/edit";
      fullPath: "/profile/edit";
      preLoaderRoute: typeof ProfileEditImport;
      parentRoute: typeof rootRoute;
    };
    "/_public/user_/$username": {
      id: "/_public/user_/$username";
      path: "/user/$username";
      fullPath: "/user/$username";
      preLoaderRoute: typeof PublicUserUsernameImport;
      parentRoute: typeof rootRoute;
    };
    "/_public/user_/$username/about": {
      id: "/_public/user_/$username/about";
      path: "/about";
      fullPath: "/user/$username/about";
      preLoaderRoute: typeof PublicUserUsernameAboutImport;
      parentRoute: typeof PublicUserUsernameImport;
    };
    "/_public/user_/$username/activities": {
      id: "/_public/user_/$username/activities";
      path: "/activities";
      fullPath: "/user/$username/activities";
      preLoaderRoute: typeof PublicUserUsernameActivitiesImport;
      parentRoute: typeof PublicUserUsernameImport;
    };
    "/_public/user_/$username/collections": {
      id: "/_public/user_/$username/collections";
      path: "/collections";
      fullPath: "/user/$username/collections";
      preLoaderRoute: typeof PublicUserUsernameCollectionsImport;
      parentRoute: typeof PublicUserUsernameImport;
    };
  }
}

// Create and export the route tree

interface ProfileRouteChildren {
  ProfileAboutRoute: typeof ProfileAboutRoute;
  ProfileActivitiesRoute: typeof ProfileActivitiesRoute;
  ProfileBadgesRoute: typeof ProfileBadgesRoute;
  ProfileCollectionsRoute: typeof ProfileCollectionsRoute;
  ProfileOverviewRoute: typeof ProfileOverviewRoute;
  ProfileSettingsRoute: typeof ProfileSettingsRoute;
  ProfileUpdatesRoute: typeof ProfileUpdatesRoute;
}

const ProfileRouteChildren: ProfileRouteChildren = {
  ProfileAboutRoute: ProfileAboutRoute,
  ProfileActivitiesRoute: ProfileActivitiesRoute,
  ProfileBadgesRoute: ProfileBadgesRoute,
  ProfileCollectionsRoute: ProfileCollectionsRoute,
  ProfileOverviewRoute: ProfileOverviewRoute,
  ProfileSettingsRoute: ProfileSettingsRoute,
  ProfileUpdatesRoute: ProfileUpdatesRoute,
};

const ProfileRouteWithChildren =
  ProfileRoute._addFileChildren(ProfileRouteChildren);

interface PublicUserUsernameRouteChildren {
  PublicUserUsernameAboutRoute: typeof PublicUserUsernameAboutRoute;
  PublicUserUsernameActivitiesRoute: typeof PublicUserUsernameActivitiesRoute;
  PublicUserUsernameCollectionsRoute: typeof PublicUserUsernameCollectionsRoute;
}

const PublicUserUsernameRouteChildren: PublicUserUsernameRouteChildren = {
  PublicUserUsernameAboutRoute: PublicUserUsernameAboutRoute,
  PublicUserUsernameActivitiesRoute: PublicUserUsernameActivitiesRoute,
  PublicUserUsernameCollectionsRoute: PublicUserUsernameCollectionsRoute,
};

const PublicUserUsernameRouteWithChildren =
  PublicUserUsernameRoute._addFileChildren(PublicUserUsernameRouteChildren);

export interface FileRoutesByFullPath {
  "/": typeof IndexRoute;
  "": typeof ProfileRouteWithChildren;
  "/archie": typeof ArchieRoute;
  "/error403": typeof Error403Route;
  "/error405": typeof Error405Route;
  "/guides": typeof GuidesRoute;
  "/privacy-policy": typeof PrivacyPolicyRoute;
  "/scan": typeof ScanRoute;
  "/scanner": typeof ScannerRoute;
  "/terms-of-use": typeof TermsOfUseRoute;
  "/about": typeof ProfileAboutRoute;
  "/activities": typeof ProfileActivitiesRoute;
  "/badges": typeof ProfileBadgesRoute;
  "/collections": typeof ProfileCollectionsRoute;
  "/overview": typeof ProfileOverviewRoute;
  "/settings": typeof ProfileSettingsRoute;
  "/updates": typeof ProfileUpdatesRoute;
  "/agamotto/$code": typeof AgamottoCodeRoute;
  "/profile/edit": typeof ProfileEditRoute;
  "/user/$username": typeof PublicUserUsernameRouteWithChildren;
  "/user/$username/about": typeof PublicUserUsernameAboutRoute;
  "/user/$username/activities": typeof PublicUserUsernameActivitiesRoute;
  "/user/$username/collections": typeof PublicUserUsernameCollectionsRoute;
}

export interface FileRoutesByTo {
  "/": typeof IndexRoute;
  "": typeof ProfileRouteWithChildren;
  "/archie": typeof ArchieRoute;
  "/error403": typeof Error403Route;
  "/error405": typeof Error405Route;
  "/guides": typeof GuidesRoute;
  "/privacy-policy": typeof PrivacyPolicyRoute;
  "/scan": typeof ScanRoute;
  "/scanner": typeof ScannerRoute;
  "/terms-of-use": typeof TermsOfUseRoute;
  "/about": typeof ProfileAboutRoute;
  "/activities": typeof ProfileActivitiesRoute;
  "/badges": typeof ProfileBadgesRoute;
  "/collections": typeof ProfileCollectionsRoute;
  "/overview": typeof ProfileOverviewRoute;
  "/settings": typeof ProfileSettingsRoute;
  "/updates": typeof ProfileUpdatesRoute;
  "/agamotto/$code": typeof AgamottoCodeRoute;
  "/profile/edit": typeof ProfileEditRoute;
  "/user/$username": typeof PublicUserUsernameRouteWithChildren;
  "/user/$username/about": typeof PublicUserUsernameAboutRoute;
  "/user/$username/activities": typeof PublicUserUsernameActivitiesRoute;
  "/user/$username/collections": typeof PublicUserUsernameCollectionsRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  "/": typeof IndexRoute;
  "/_profile": typeof ProfileRouteWithChildren;
  "/archie": typeof ArchieRoute;
  "/error403": typeof Error403Route;
  "/error405": typeof Error405Route;
  "/guides": typeof GuidesRoute;
  "/privacy-policy": typeof PrivacyPolicyRoute;
  "/scan": typeof ScanRoute;
  "/scanner": typeof ScannerRoute;
  "/terms-of-use": typeof TermsOfUseRoute;
  "/_profile/about": typeof ProfileAboutRoute;
  "/_profile/activities": typeof ProfileActivitiesRoute;
  "/_profile/badges": typeof ProfileBadgesRoute;
  "/_profile/collections": typeof ProfileCollectionsRoute;
  "/_profile/overview": typeof ProfileOverviewRoute;
  "/_profile/settings": typeof ProfileSettingsRoute;
  "/_profile/updates": typeof ProfileUpdatesRoute;
  "/agamotto_/$code": typeof AgamottoCodeRoute;
  "/profile/edit": typeof ProfileEditRoute;
  "/_public/user_/$username": typeof PublicUserUsernameRouteWithChildren;
  "/_public/user_/$username/about": typeof PublicUserUsernameAboutRoute;
  "/_public/user_/$username/activities": typeof PublicUserUsernameActivitiesRoute;
  "/_public/user_/$username/collections": typeof PublicUserUsernameCollectionsRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | "/"
    | ""
    | "/archie"
    | "/error403"
    | "/error405"
    | "/guides"
    | "/privacy-policy"
    | "/scan"
    | "/scanner"
    | "/terms-of-use"
    | "/about"
    | "/activities"
    | "/badges"
    | "/collections"
    | "/overview"
    | "/settings"
    | "/updates"
    | "/agamotto/$code"
    | "/profile/edit"
    | "/user/$username"
    | "/user/$username/about"
    | "/user/$username/activities"
    | "/user/$username/collections";
  fileRoutesByTo: FileRoutesByTo;
  to:
    | "/"
    | ""
    | "/archie"
    | "/error403"
    | "/error405"
    | "/guides"
    | "/privacy-policy"
    | "/scan"
    | "/scanner"
    | "/terms-of-use"
    | "/about"
    | "/activities"
    | "/badges"
    | "/collections"
    | "/overview"
    | "/settings"
    | "/updates"
    | "/agamotto/$code"
    | "/profile/edit"
    | "/user/$username"
    | "/user/$username/about"
    | "/user/$username/activities"
    | "/user/$username/collections";
  id:
    | "__root__"
    | "/"
    | "/_profile"
    | "/archie"
    | "/error403"
    | "/error405"
    | "/guides"
    | "/privacy-policy"
    | "/scan"
    | "/scanner"
    | "/terms-of-use"
    | "/_profile/about"
    | "/_profile/activities"
    | "/_profile/badges"
    | "/_profile/collections"
    | "/_profile/overview"
    | "/_profile/settings"
    | "/_profile/updates"
    | "/agamotto_/$code"
    | "/profile/edit"
    | "/_public/user_/$username"
    | "/_public/user_/$username/about"
    | "/_public/user_/$username/activities"
    | "/_public/user_/$username/collections";
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  ProfileRoute: typeof ProfileRouteWithChildren;
  ArchieRoute: typeof ArchieRoute;
  Error403Route: typeof Error403Route;
  Error405Route: typeof Error405Route;
  GuidesRoute: typeof GuidesRoute;
  PrivacyPolicyRoute: typeof PrivacyPolicyRoute;
  ScanRoute: typeof ScanRoute;
  ScannerRoute: typeof ScannerRoute;
  TermsOfUseRoute: typeof TermsOfUseRoute;
  AgamottoCodeRoute: typeof AgamottoCodeRoute;
  ProfileEditRoute: typeof ProfileEditRoute;
  PublicUserUsernameRoute: typeof PublicUserUsernameRouteWithChildren;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  ProfileRoute: ProfileRouteWithChildren,
  ArchieRoute: ArchieRoute,
  Error403Route: Error403Route,
  Error405Route: Error405Route,
  GuidesRoute: GuidesRoute,
  PrivacyPolicyRoute: PrivacyPolicyRoute,
  ScanRoute: ScanRoute,
  ScannerRoute: ScannerRoute,
  TermsOfUseRoute: TermsOfUseRoute,
  AgamottoCodeRoute: AgamottoCodeRoute,
  ProfileEditRoute: ProfileEditRoute,
  PublicUserUsernameRoute: PublicUserUsernameRouteWithChildren,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_profile",
        "/archie",
        "/error403",
        "/error405",
        "/guides",
        "/privacy-policy",
        "/scan",
        "/scanner",
        "/terms-of-use",
        "/agamotto_/$code",
        "/profile/edit",
        "/_public/user_/$username"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_profile": {
      "filePath": "_profile.tsx",
      "children": [
        "/_profile/about",
        "/_profile/activities",
        "/_profile/badges",
        "/_profile/collections",
        "/_profile/overview",
        "/_profile/settings",
        "/_profile/updates"
      ]
    },
    "/archie": {
      "filePath": "archie.tsx"
    },
    "/error403": {
      "filePath": "error403.tsx"
    },
    "/error405": {
      "filePath": "error405.tsx"
    },
    "/guides": {
      "filePath": "guides.tsx"
    },
    "/privacy-policy": {
      "filePath": "privacy-policy.tsx"
    },
    "/scan": {
      "filePath": "scan.tsx"
    },
    "/scanner": {
      "filePath": "scanner.tsx"
    },
    "/terms-of-use": {
      "filePath": "terms-of-use.tsx"
    },
    "/_profile/about": {
      "filePath": "_profile.about.tsx",
      "parent": "/_profile"
    },
    "/_profile/activities": {
      "filePath": "_profile.activities.tsx",
      "parent": "/_profile"
    },
    "/_profile/badges": {
      "filePath": "_profile.badges.tsx",
      "parent": "/_profile"
    },
    "/_profile/collections": {
      "filePath": "_profile.collections.tsx",
      "parent": "/_profile"
    },
    "/_profile/overview": {
      "filePath": "_profile.overview.tsx",
      "parent": "/_profile"
    },
    "/_profile/settings": {
      "filePath": "_profile.settings.tsx",
      "parent": "/_profile"
    },
    "/_profile/updates": {
      "filePath": "_profile.updates.tsx",
      "parent": "/_profile"
    },
    "/agamotto_/$code": {
      "filePath": "agamotto_.$code.tsx"
    },
    "/profile/edit": {
      "filePath": "profile.edit.tsx"
    },
    "/_public/user_/$username": {
      "filePath": "_public.user_.$username.tsx",
      "children": [
        "/_public/user_/$username/about",
        "/_public/user_/$username/activities",
        "/_public/user_/$username/collections"
      ]
    },
    "/_public/user_/$username/about": {
      "filePath": "_public.user_.$username.about.tsx",
      "parent": "/_public/user_/$username"
    },
    "/_public/user_/$username/activities": {
      "filePath": "_public.user_.$username.activities.tsx",
      "parent": "/_public/user_/$username"
    },
    "/_public/user_/$username/collections": {
      "filePath": "_public.user_.$username.collections.tsx",
      "parent": "/_public/user_/$username"
    }
  }
}
ROUTE_MANIFEST_END */
