import { settingsTabTrigger } from "@/components/constants/tabs.ts";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/components/core/navigations/tabs.tsx";
import SettingsAccounts from "@/components/features/settings/settings-accounts.tsx";
import ProfileSecurity from "@/components/features/settings/settings-security.tsx";
import {
	IconAccounts,
	IconNotifications,
	IconSecurity,
	IconSettings,
	IconTicket,
} from "@/components/mvicons";
import type { User } from "@/types/user.ts";
import { IconX } from "@tabler/icons-react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import ReactDOM from "react-dom";

interface SettingsProps {
	profile: User;
	isOpen: boolean;
	onClose: () => void;
}

const Settings = ({ profile, isOpen, onClose }: SettingsProps) => {
	const isProduction = import.meta.env.VITE_PUBLIC_ENV === "production";

	return ReactDOM.createPortal(
		<AnimatePresence>
			{isOpen && (
				<motion.div
					className={clsx(
						"font-aller z-50 fixed right-0 bottom-0 left-0 flex items-center justify-center min-h-[100svh]",
						isProduction ? "top-0" : "top-8",
					)}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.1 }}
				>
					{/* overlay */}
					<motion.div
						className="absolute inset-0 bg-mvdark-950/60"
						initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
						animate={{ opacity: 1, backdropFilter: "blur(3px)" }}
						exit={{ opacity: 0, backdropFilter: "blur(0px)" }}
						transition={{ duration: 0.1 }}
					/>

					{/* main container */}
					<motion.div
						className="relative flex flex-col h-auto w-auto"
						initial={{ opacity: 0, scale: 0.95 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.95 }}
						transition={{ duration: 0.1 }}
					>
						{/* close button */}
						<button
							onClick={() => {
								onClose();
							}}
							type="button"
							className="z-10 absolute left-4 top-4 inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white"
						>
							<IconX size={28} stroke={2} />
						</button>

						{/* main content */}
						<motion.div className="relative flex flex-col justify-center items-center w-screen md:w-full md:max-w-[960px] xl:min-w-[960px] rounded-none md:rounded-4xl border border-white/10 bg-mvdark-950 bg-opacity-95 shadow-xl overflow-hidden">
							<div className="flex flex-col w-full p-12 gap-8 overflow-auto">
								<div className="relative flex flex-col w-full gap-2">
									<div className="flex flex-row justify-center items-center w-full">
										<div className="inline-flex flex-row items-center gap-2 text-white">
											<IconSettings size={24} />
											<span className="text-xl">Settings</span>
										</div>
									</div>
								</div>
								<Tabs defaultValue="accounts">
									<TabsList className="!py-4">
										<TabsTrigger
											className={settingsTabTrigger}
											value="accounts"
										>
											<IconAccounts size={24} />
											<span className="hidden sm:inline-flex">Accounts</span>
										</TabsTrigger>
										<TabsTrigger
											className={settingsTabTrigger}
											value="notifications"
										>
											<IconNotifications size={18} />
											<span className="hidden sm:inline-flex">
												Notifications
											</span>
										</TabsTrigger>
										<TabsTrigger
											className={settingsTabTrigger}
											value="security"
										>
											<IconSecurity size={18} />
											<span className="hidden sm:inline-flex">Security</span>
										</TabsTrigger>
										<TabsTrigger
											disabled
											className={settingsTabTrigger}
											value="subscriptions"
										>
											<IconTicket size={22} />
											<span className="hidden sm:inline-flex">
												Subscriptions
											</span>
										</TabsTrigger>
									</TabsList>
									<TabsContent value="accounts">
										<SettingsAccounts />
									</TabsContent>
									<TabsContent value="notifications">
										<div className="flex flex-col w-full gap-2">
											<div className="flex flex-row items-center gap-2">
												<span className="text-sm font-semibold text-white">
													Your Notifications
												</span>
												<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/30 font-bold text-2xs text-mvdark-950 uppercase">
													Coming Soon
												</span>
											</div>
											<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
												<div className="inline-flex text-white">
													<IconNotifications />
												</div>
												<div className="flex flex-col w-full max-w-[280px] gap-2">
													<span className="text-sm font-semibold text-white leading-tight">
														Your latest and greatest content.
													</span>
													<span className="text-sm text-white/70 leading-tight">
														Series you’re reading are automatically waiting for
														you here. Add them to your list and queue them up
														here as well.
													</span>
												</div>
											</div>
										</div>
									</TabsContent>
									<TabsContent value="security">
										<ProfileSecurity profile={profile} />
										{/*<div className="flex flex-col w-full gap-2">
											<div className="flex flex-row items-center gap-2">
												<span className="text-sm font-semibold text-white">
													Your Security
												</span>
												<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/30 font-bold text-2xs text-mvdark-950 uppercase">
													Coming Soon
												</span>
											</div>
											<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
												<div className="inline-flex text-white">
													<IconSecurity />
												</div>
												<div className="flex flex-col w-full max-w-[280px] gap-2">
													<span className="text-sm font-semibold text-white leading-tight">
														Your latest and greatest content.
													</span>
													<span className="text-sm text-white/70 leading-tight">
														Series you’re reading are automatically waiting for
														you here. Add them to your list and queue them up
														here as well.
													</span>
												</div>
											</div>
										</div>*/}
									</TabsContent>
									<TabsContent value="subscriptions">
										<div className="flex flex-col w-full gap-2">
											<div className="flex flex-row items-center gap-2">
												<span className="text-sm font-semibold text-white">
													Your Subscriptions
												</span>
												<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/30 font-bold text-2xs text-mvdark-950 uppercase">
													Coming Soon
												</span>
											</div>
											<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
												<div className="inline-flex text-white">
													<IconTicket />
												</div>
												<div className="flex flex-col w-full max-w-[280px] gap-2">
													<span className="text-sm font-semibold text-white leading-tight">
														Your latest and greatest content.
													</span>
													<span className="text-sm text-white/70 leading-tight">
														Series you’re reading are automatically waiting for
														you here. Add them to your list and queue them up
														here as well.
													</span>
												</div>
											</div>
										</div>
									</TabsContent>
								</Tabs>
							</div>
							{/*<div className="z-[2] absolute w-[380px] h-[380px] rounded-full blur-[100px] bg-black opacity-50" />
              <div className="z-[1] absolute scanner-modal-container inset-0 blur-[40px]" />*/}
						</motion.div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>,
		document.body,
	);
};

export default Settings;
