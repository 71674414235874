import profileService from "@/api/services/profile-service.ts";
import { queryClient } from "@/query-client.ts";
import { queryOptions, useMutation } from "@tanstack/react-query";

export const profileQueryOptions = () => {
	return queryOptions({
		queryKey: ["profile"],
		queryFn: profileService.getProfile,
	});
};

export const useProfileMutation = () => {
	return useMutation({
		mutationKey: ["profile"],
		mutationFn: profileService.update,
		meta: {
			successMessage: "Profile updated",
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["profile"] });
			queryClient.invalidateQueries({ queryKey: ["quests"] });
		},
	});
};

export const useProfileBackgroundColorMutation = () => {
	return useMutation({
		mutationKey: ["profile"],
		mutationFn: profileService.updateBgColor,
		onSettled: () => queryClient.invalidateQueries({ queryKey: ["profile"] }),
	});
};

export const useProfilePictureMutation = () => {
	return useMutation({
		mutationKey: ["profile"],
		mutationFn: profileService.updateProfilePicture,
		onSettled: () => queryClient.invalidateQueries({ queryKey: ["profile"] }),
	});
};

export const useProfileBannerMutation = () => {
	return useMutation({
		mutationKey: ["profile"],
		mutationFn: profileService.updateProfileBanner,
		onSettled: () => queryClient.invalidateQueries({ queryKey: ["profile"] }),
	});
};

export const useProfileDismissWelcome = () => {
	return useMutation({
		mutationKey: ["profile"],
		mutationFn: profileService.dismissWelcome,
		onSettled: () => queryClient.invalidateQueries({ queryKey: ["profile"] }),
	});
};
